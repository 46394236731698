//** React Imports */
import React, { useState, useEffect, useMemo } from "react";
//** Ant Component Imports */
import {
  Button,
  Descriptions,
  Typography,
  message,
  Grid,
  Tooltip,
  Collapse,
  Switch,
} from "antd";
//** Ant Icon Imports */
import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import SuspendIcon from "assets/images/SuspendIcon.svg";

//** Redux Imports */
import { useSelector } from "react-redux";
//** Sdk Imports */
import sdk from "sdk/Accounts";

import { API, graphqlOperation } from "aws-amplify";
import { listApiKeys } from "graphql/queries";
import SkeletonBlock from "Common/Skeleton/SkeletonBlock.js";
import { getAccountCustom } from "graphql/customQueries";
import { buttonText, schoolDetailsText } from "utility/constants";

//** React Hooks Imports */
import { useTranslation } from "react-i18next";
import Loader from "Common/Components/Loader/Loader";
import SchoolDetailsSDK from "sdk/SchoolDetailsSDK";
import CaretUp from "Common/Components/CaretUp/CaretUp";
import CustomModal from "Common/Components/CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import { Images } from "assets/images/Images";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";

/**
 * @function CompanyDetails functional base component
 * @returns {SchoolDetails} Component
 */
export default function CompanyDetails() {
  const { Title, Text } = Typography;
  const { Panel } = Collapse;
  const { useBreakpoint } = Grid;
  const { t } = useTranslation();

  // Dev comment :- selectedCompanyName
  const { groups, isScopeNormalUser } = useSelector(
    (state) => state.loginReducer
  );
  const isAdmin = groups.includes("admin");
  const isHidden =
    groups?.includes("normal_user") ||
    groups?.includes("SchoolAdmin") ||
    isScopeNormalUser;

  const isReportUser = groups?.includes("ReportUser");

  const {
    School_Information_Text,
    Personally_Identifiable_Text,
    Your_Secret_Text,
    Generate_New_Secret_Key_Text,
    Unsuspend_School_Text,
    Suspend_School_Text,
    Unique_Secret_Key_Text,
    No_key_Text,
    Enable_Disable_PII_Text,
    PII_Information_Text,
  } = schoolDetailsText;

  const {
    Enable_Btn,
    Disable_Btn,
    Cancel,
    Yes_Sure_Text,
    Unsuspend_School_Btn,
    Suspend_School_Btn,
  } = buttonText;

  const { ApiKeyDelImg } = Images.ModalImg;

  const [acct, setAcct] = useState(null);
  // Dev Comment :-
  // const [isAdminScoped, setisAdminScoped] = useState(false);
  const [isCompanySuspendModelOpen, setIsCompanysuspendModelOpen] =
    useState(false);
  const [companySuspendLoading, setCompanySuspendLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [secretKeyloading, setSecretKeyLoading] = useState(true);
  const [secretKeyVisible, setSecretKeyVisible] = useState({
    1: false,
    2: false,
  });
  const [secretKeys, setSecretKeys] = useState([]);
  const [piiFlag, setPiiFlag] = useState(acct?.piiInformation || false);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });
  const [apiKeyConfirm, setApiKeyConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const fetchSecretsKey = async () => {
    setSecretKeyLoading(true);
    try {
      let keys = await API.graphql(graphqlOperation(listApiKeys));
      let tempSecretKeys = keys?.data?.listApiKeys?.items;
      let keyList = tempSecretKeys.filter(
        (secretkey) => secretkey?.account === groups[0]
      );
      setSecretKeys(keyList);
    } catch (e) {
      console.log(e);
      return { errors: ["Failed retrieving keys"] };
    } finally {
      setSecretKeyLoading(false);
    }
  };
  useEffect(() => {
    fetchSecretsKey();
    // eslint-disable-next-line
  }, []);

  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await API.graphql(
        graphqlOperation(getAccountCustom, { id: accountID })
      );
      setCompanyData(account.data.getAccount);
      setLoading(false);
    }
  };

  const handleAddNewkey = async (e) => {
    e.stopPropagation();
    setSecretKeyLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;

    await sdk
      .addSecretsKey(accountID)
      .then(async () => {
        // message.success("Secret key added successfully");
        setApiResultModal(() => ({
          message: "Secret key added successfully",
          isApiResultModelOpen: true,
          type: "success",
        }));
        await fetchSecretsKey();
      })
      .catch(() => {
        // message.success("Failed to add secret key");
        setApiResultModal(() => ({
          message: "Failed to add secret key",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => {
        setSecretKeyLoading(false);
      });
  };

  const handleDisable = async (disableId, value) => {
    setSecretKeyLoading(false);

    const accountID = groups.length > 0 ? groups[0] : null;
    await sdk
      .disableSecretsKey(accountID, disableId, value)
      .then(async (res) => {
        if (res?.error) {
          // message.error(res?.error);
          setApiResultModal(() => ({
            message: res?.error,
            isApiResultModelOpen: true,
            type: "failure",
          }));
        } else {
          if (value === true) {
            // message.success("Disable successfully");
            setApiResultModal(() => ({
              message: "Disable successfully",
              isApiResultModelOpen: true,
              type: "failure",
            }));
            await fetchSecretsKey();
          } else {
            // message.success("Enable successfully");
            setApiResultModal(() => ({
              message: "Enable successfully",
              isApiResultModelOpen: true,
              type: "success",
            }));
            await fetchSecretsKey();
          }
        }
      })
      .catch(() => {
        // message.error("Failed disable secret key");
        setApiResultModal(() => ({
          message: "Failed disable secret key",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => {
        setSecretKeyLoading(false);
      });
  };

  const handleKeyDelete = async () => {
    setSecretKeyLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    await sdk
      .deleteSecretsKey(accountID, deleteId)
      .then(async () => {
        // message.success("Deleted successfully");
        setApiResultModal(() => ({
          message: "Deleted successfully",
          isApiResultModelOpen: true,
          type: "success",
        }));
        await fetchSecretsKey();
      })
      .catch(() => {
        // message.error("Failed delete secret key");
        setApiResultModal(() => ({
          message: "Failed to delete secret key",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => {
        setSecretKeyLoading(false);
      });
  };

  // Dev Comment :-
  // useEffect(() => {
  //   if (groups && groups[0]) {
  //     if (selectedCompanyName) {
  //       setisAdminScoped(true);
  //     }
  //   }
  // }, [groups, selectedCompanyName]);

  const setCompanyData = (account) => {
    if (!account?.url?.includes("http")) {
      account.url = "https://" + account?.url;
    }
    setAcct(account);
  };

  const PII = useMemo(
    () => [
      {
        name: "First Name",
        description: "Johnson",
      },
      {
        name: "Last Name",
        description: "Doe",
      },
      {
        name: "Middle Name",
        description: "August",
      },
      {
        name: "Preferred Name",
        description: "John",
      },
      {
        name: "Maiden Name",
        description: "Doe",
      },
      {
        name: "Email",
        description: "john@example.com",
      },
      {
        name: "Parent Email(s)",
        description: "john.parent@example.com",
      },
      {
        name: "Personal Website",
        description: "john.webapp.com",
      },
      {
        name: "Mailing Address(street-level)",
        description: "example.com",
      },
    ],
    []
  );
  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (acct) {
      setPiiFlag(acct?.piiInformation);
    }
  }, [acct]);

  const handleCompanySuspend = async () => {
    if (acct) {
      setCompanySuspendLoading(true);
      let companyId = acct?.id;
      let suspendFlag = acct?.disabled === true ? false : true;
      let result = await sdk.companySuspension(companyId, suspendFlag);
      if (result?.error) {
        // message.error(result?.error, 5);
        setApiResultModal(() => ({
          message: result?.error,
          isApiResultModelOpen: true,
          type: "failure",
        }));
      } else {
        // message.success(
        // suspendFlag
        //   ? "School suspended successfully"
        //   : "School unsuspended successfully",
        //   5
        // );
        setApiResultModal(() => ({
          message: suspendFlag
            ? "School suspended successfully"
            : "School unsuspended successfully",
          isApiResultModelOpen: true,
          type: "success",
        }));
        fetchAccountDetails();
      }
    }

    setIsCompanysuspendModelOpen(false);
    setCompanySuspendLoading(false);
  };

  const screens = useBreakpoint();

  const handleVisibility = (e, index) => {
    e.preventDefault();
    setSecretKeyVisible((pS) => {
      pS[index] = !pS[index];
      return { ...pS };
    });
  };

  const handlePII = (flag, e) => {
    e.stopPropagation();
    SchoolDetailsSDK.piiInformationHandler(acct?.id, flag).then(() => {
      fetchAccountDetails();
    });
  };

  return (
    <>
      <div className="school-detail flex-col g-1">
        {acct ? (
          <>
            <Collapse
              bordered={false}
              defaultActiveKey={["3"]}
              expandIcon={({ isActive }) => (
                <span className="p-3 m-0">
                  <CaretUp className={isActive ? "" : "rotate-90"} />
                </span>
              )}
              className="data-source-collapse data-source-box "
            >
              <Panel
                key="1"
                className="data-source-panel"
                header={
                  <Title level={4} className="school-information mb-0">
                    {t(School_Information_Text)}
                  </Title>
                }
              >
                <Descriptions
                  bordered
                  column={1}
                  className="school-description-container"
                >
                  <Descriptions.Item label="School Name">
                    <span className="fs-18">
                      {acct && acct.company ? acct.company : "-"}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label="School URL">
                    {acct ? (
                      <a
                        className="fs-18"
                        href={acct.url}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {acct.url}
                      </a>
                    ) : (
                      "-"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Account ID">
                    <span className="fs-18">
                      {acct ? (
                        <Typography.Text
                          copyable={{ text: acct.id }}
                          className="flex-between"
                        >
                          {acct.id}
                        </Typography.Text>
                      ) : (
                        "-"
                      )}
                    </span>
                  </Descriptions.Item>
                </Descriptions>
              </Panel>

              <Panel
                key="2"
                // className="secret-key-container"
                className="data-source-panel "
                header={
                  <div className="secret-key-header-container flex-row">
                    <span className="secret-key-header">
                      {t(Your_Secret_Text)}
                    </span>

                    <div className="d-flex gap-3 align-items-center ">
                      {secretKeyloading ? <Loader /> : null}
                      {isHidden || isReportUser ? null : (
                        <Button
                          type="primary"
                          disabled={secretKeys.length >= 2}
                          onClick={(e) => handleAddNewkey(e)}
                        >
                          {t(Generate_New_Secret_Key_Text)}
                        </Button>
                      )}
                    </div>
                  </div>
                }
              >
                <div className="secret-key-content-container">
                  {secretKeys.length > 0 &&
                    secretKeys.map((item, index) => {
                      index = index + 1;
                      return (
                        <>
                          <div
                            style={{ display: screens.xs ? "" : "flex" }}
                            className="secret-key"
                            key={index}
                          >
                            <Typography.Text
                              copyable={{ text: item?.key }}
                              className="secret-key-wrapper"
                            >
                              <div
                                className="flex-between w-100 align-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                <div
                                  className={
                                    (secretKeyVisible[index] === true
                                      ? ""
                                      : "secret-key-visible ") +
                                    (item?.disabled ? "secret-key-value" : "") +
                                    "secret-key-value-container"
                                  }
                                >
                                  {item?.key}
                                </div>

                                {secretKeyVisible[index] ? (
                                  <EyeOutlined
                                    onClick={(e) => handleVisibility(e, index)}
                                  />
                                ) : (
                                  <EyeInvisibleOutlined
                                    onClick={(e) => handleVisibility(e, index)}
                                  />
                                )}
                              </div>
                            </Typography.Text>
                            {isHidden || isReportUser ? null : (
                              <div className="secret-key-buttons-container">
                                <Button
                                  className="delete-btn"
                                  shape="circle"
                                  type="text"
                                  onClick={() => {
                                    setApiKeyConfirm(true);
                                    setDeleteId(item?.id);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>

                                {item?.disabled ? (
                                  <Button
                                    className="add-btn enable-btn"
                                    shape="round"
                                    onClick={() =>
                                      handleDisable(item?.id, false)
                                    }
                                  >
                                    {t(Enable_Btn)}
                                  </Button>
                                ) : (
                                  <Button
                                    className="add-btn disable-btn"
                                    shape="round"
                                    onClick={() =>
                                      handleDisable(item?.id, true)
                                    }
                                  >
                                    {t(Disable_Btn)}
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}

                  {secretKeys.length === 0 && <> {t(No_key_Text)} </>}
                  <span
                    className="secret-key-text"
                    dangerouslySetInnerHTML={{
                      __html: t(Unique_Secret_Key_Text),
                    }}
                  ></span>
                </div>
              </Panel>

              <Panel
                key="3"
                className="data-source-panel"
                header={
                  <div className="d-flex justify-content-between align-items-center">
                    <Title level={4} className="school-information mb-0">
                      {t(Personally_Identifiable_Text)}
                    </Title>
                    {!isReportUser ? (
                      <div className="d-flex gap-3 align-items-center ">
                        {loading ? <Loader /> : null}
                        <div className="flex-col">
                          <Tooltip
                            title={t(Enable_Disable_PII_Text)}
                            placement="topLeft"
                            color="#F5DB5D"
                          >
                            <Switch
                              value={piiFlag}
                              checked={piiFlag}
                              onChange={(value, e) => handlePII(value, e)}
                            />
                          </Tooltip>
                          {!piiFlag ? (
                            <Text>Enable</Text>
                          ) : (
                            <Text> Disable </Text>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                }
              >
                <div className="flex-col gap-3">
                  <Text className="pii-information-text">
                    {t(PII_Information_Text)}
                  </Text>
                  <div className="pii-field-container">
                    {PII.map((item) => item.name).join(",")}
                  </div>
                </div>
              </Panel>
            </Collapse>

            {isAdmin ? (
              <Button
                className="suspendBtn"
                onClick={() => setIsCompanysuspendModelOpen(true)}
              >
                {acct?.disabled === true ? (
                  <span> {t(Unsuspend_School_Btn)} </span>
                ) : (
                  <span>{t(Suspend_School_Btn)}</span>
                )}
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <SkeletonBlock />
          </>
        )}
        <CustomModal
          visible={isCompanySuspendModelOpen}
          closable={true}
          onCancel={() => setIsCompanysuspendModelOpen(false)}
          centered
          width={430}
          footer={null}
          className="suspend-company-footer"
        >
          <div className="delete-modal-body">
            <img
              src={SuspendIcon}
              alt="suspend school"
              className="d-flex mx-auto"
            />
            <Typography.Text className="suspend-company-text">
              {acct?.disabled === true
                ? t(Unsuspend_School_Text)
                : t(Suspend_School_Text)}
            </Typography.Text>
            <div className="suspend-company-btn-container">
              <Button
                className="secondary-btn"
                onClick={() => setIsCompanysuspendModelOpen(false)}
              >
                {t(Cancel)}
              </Button>
              <Button
                loading={companySuspendLoading}
                onClick={() => {
                  handleCompanySuspend();
                }}
                type="primary"
              >
                {t(Yes_Sure_Text)}
              </Button>
            </div>
          </div>
        </CustomModal>
      </div>
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />

      <SuccessfulModal
        successImage={ApiKeyDelImg}
        isShowSuccessfulModal={apiKeyConfirm}
        setIsShowSuccessfulModal={setApiKeyConfirm}
        modalText="Are you sure you want to delete the Secret key?"
        width={500}
        okBtnText="Delete"
        cancelBtnText="Cancel"
        okBtnClickHandler={() => handleKeyDelete()}
      />
    </>
  );
}
