export const Images = {
  Billing: {
    StarterPlanImg: require("../images/Billing/Starter-Plan.svg").default,
    ProPlanImg: require("../images/Billing/Pro-Plan.svg").default,
    PremiumPlanImg: require("../images/Billing/Premium-Plan.svg").default,
    PaymentCardImg: require("../images/Billing/PaymentCard.svg").default,
    NoPlanActiveImg: require("../images/Billing/NoPlanActive.svg").default,
    ManageBillingAdminAssign:
      require("../images/Billing/ManageBillingAdminAssign.svg").default,
    PlanPurchasedImg: require("../images/Billing/PlanPurchased.svg").default,
  },
  Home: {
    InviteIconImg: require("../images/Home/InviteIcon.svg").default,
    ManagePlanImg: require("../images/Home/ManagePlan.svg").default,
    HelpfullTipsImg: require("../images/Home/HelpfullTips.svg").default,
    ConfigureImg: require("../images/Home/Configure.svg").default,
  },
  AccountSetup: {
    ReqPending: require("../images/AccountSetup/reqPending.svg").default,
    RequestAccessSvg: require("../images/AccountSetup/ReqAccess.svg").default,
    NewAccountSVG: require("../images/AccountSetup//NewAccount.svg").default,
  },
  ManageFiles: {
    ExcelSvg: require("../images/ManageFiles/Excel.svg").default,
    RefreshIntervalModalSvg:
      require("../images/ManageFiles/RefreshIntervalModal.svg").default,
    MultiWorksheetSvg: require("../images/ManageFiles/MultiWorksheet.svg")
      .default,
    UploadedMultiWorkSheetSvg:
      require("../images/ManageFiles/UploadedMultiworksheet.svg").default,
    UploadedRefreshIntervalSvg:
      require("../images/ManageFiles/UploadedRefreshInterval.svg").default,
    RecentFilesSheetsSvg: require("../images/ManageFiles/RecentFilesSheets.svg")
      .default,
    WorksheetUploadedSvg: require("../images/ManageFiles/WorksheetUploaded.svg")
      .default,
    FileErrorSvg: require("../images/ManageFiles/FileError.svg").default,
    ScreenshotFileSvg: require("../images/ManageFiles/ScreenshotFile.svg")
      .default,
    NoWorkSheetSvg: require("../images/ManageFiles/NoWorkSheet.svg").default,
    NoCustomFile: require("../images/ManageFiles/NoCustomFile.png"),
  },
  ManageDatasetImg: {
    SaveModalSvg: require("../images/ManageDataset/SaveConfirmationModal.svg")
      .default,
    RedoModalSvg: require("../images/ManageDataset/RedoConfirmation.svg")
      .default,
  },
  Dashboard: {
    HeroImage: require("../images/Dashboards/DashboardHero.svg").default,
    NoCustomFile: require("../images/Dashboards/NoCustomFile.svg").default,
    NoDataProvider: require("../images/Dashboards/NoDataProvider.svg").default,
    NoApiCalls: require("../images/Dashboards/NoApiCalls.svg").default,
  },
  Icons: {
    CaretUpIcon: require("../images/icons/caretup.svg").default,
    FileIcon: require("../images/DataSourceFile.svg").default,
  },
  DataSource: {
    BlackbaudImg: require("../images/DataSource/bblogo.png"),
    VeracrossImg: require("../images/DataSource/Veracross.svg").default,
    RavennaImg: require("../images/DataSource/ravenna.svg").default,
    CustomDataImg: require("../images/DataSource/Custom_Data.svg").default,
    DataSourceManageFilesImg:
      require("../images/DataSource/DataSourceManageFiles.svg").default,
    CustomDataModelImg: require("../images/Billing/CustomDataModel.svg")
      .default,
    NoDataSourceImg: require("../images/DataSource/NoDataSource.svg").default,
    AdvancedListDataSourceImg:
      require("../images/DataSource/AdvancedListDataSource.svg").default,
    VeracrossConfigImg: require("../images/DataSource/VeracrossConfig.svg")
      .default,
  },
  AdvancedList: {
    NoAdvancedList: require("../images/AdvancedList/NoAdvancedList.png"),
    ModalSvg: require("../images/AdvancedList/AdvancedListFile.svg").default,
    FileInProgressSvg: require("../images/AdvancedList/FileInProgress.svg")
      .default,
    NoAdvancedListSelectSvg:
      require("../images/AdvancedList/NoAdvancedListSelect.svg").default,
  },
  ModalImg: {
    LogoutSvg: require("../images/Modals/Logout.svg").default,
    FailureModalImg: require("../images/Modals/Failure.svg").default,
    SuccessModalImg: require("../images/Modals/Success.svg").default,
    ApiKeyDelImg: require("../images/Modals/ApiKeyDel.svg").default,
    AssignNewImg: require("../images/Modals/AssignNew.svg").default,
    ApplyRuleImg: require("../images/Modals/ApplyRule.svg").default,
    UnsaveImg: require("../images/Modals/UnSave.svg").default,
    VeracrossDeleteImg: require("../images/Modals/VeracrossDelete.svg").default,
  },
  QuicksightDashboard: {
    QuicksightDashboardSvg:
      require("../images/QuickSight/QuickSightDashboards.svg").default,
    DashboardIconSvg: require("../images/QuickSight/DashboardIcon.svg").default,
    NoDashboardSvg: require("../images/QuickSight/NoDashboard.svg").default,
  },
};
