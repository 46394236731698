/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDataSetup = /* GraphQL */ `
  mutation CreateDataSetup(
    $input: CreateDataSetupInput!
    $condition: ModeldataSetupConditionInput
  ) {
    createDataSetup(input: $input, condition: $condition) {
      id
      school_id
      dimension_or_metrics
      field_key
      friendly_name
      field_description
      field_type_GDS
      field_type_PBI
      data_source
      data_set
      api_endpoint
      name_by_school
      worksheet_name
      googlesheet_name
      worksheet_id
      googlesheet_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataSetup = /* GraphQL */ `
  mutation UpdateDataSetup(
    $input: UpdateDataSetupInput!
    $condition: ModeldataSetupConditionInput
  ) {
    updateDataSetup(input: $input, condition: $condition) {
      id
      school_id
      dimension_or_metrics
      field_key
      friendly_name
      field_description
      field_type_GDS
      field_type_PBI
      data_source
      data_set
      api_endpoint
      name_by_school
      worksheet_name
      googlesheet_name
      worksheet_id
      googlesheet_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataSetup = /* GraphQL */ `
  mutation DeleteDataSetup(
    $input: DeleteDataSetupInput!
    $condition: ModeldataSetupConditionInput
  ) {
    deleteDataSetup(input: $input, condition: $condition) {
      id
      school_id
      dimension_or_metrics
      field_key
      friendly_name
      field_description
      field_type_GDS
      field_type_PBI
      data_source
      data_set
      api_endpoint
      name_by_school
      worksheet_name
      googlesheet_name
      worksheet_id
      googlesheet_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      company_description
      url
      disabled
      piiInformation
      isSchoolAdmin
      email
      users
      createdAt
      updatedAt
      data_setup {
        nextToken
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      metadata
      owners
      editors
      viewers
      datasource
      dataprovider
      advancedList {
        nextToken
        __typename
      }
      request_googlesheet {
        nextToken
        __typename
      }
      recurringSync {
        nextToken
        __typename
      }
      apihistory {
        nextToken
        __typename
      }
      dataSet {
        nextToken
        __typename
      }
      requests {
        nextToken
        __typename
      }
      plan {
        id
        name
        planDetails
        Targets
        bulletPoints
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createAdvancedListDetail = /* GraphQL */ `
  mutation CreateAdvancedListDetail(
    $input: CreateAdvancedListDetailInput!
    $condition: ModelAdvancedListDetailConditionInput
  ) {
    createAdvancedListDetail(input: $input, condition: $condition) {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdvancedListDetail = /* GraphQL */ `
  mutation UpdateAdvancedListDetail(
    $input: UpdateAdvancedListDetailInput!
    $condition: ModelAdvancedListDetailConditionInput
  ) {
    updateAdvancedListDetail(input: $input, condition: $condition) {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdvancedListDetail = /* GraphQL */ `
  mutation DeleteAdvancedListDetail(
    $input: DeleteAdvancedListDetailInput!
    $condition: ModelAdvancedListDetailConditionInput
  ) {
    deleteAdvancedListDetail(input: $input, condition: $condition) {
      id
      school_id
      list_name
      list_id
      datasource
      dataset
      status
      preconfigure
      last_sync_date
      interval_type
      recurring_sync_status
      custom_interval
      custom_type
      custom_value
      is_initial_sync
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataPackageDetail = /* GraphQL */ `
  mutation CreateDataPackageDetail(
    $input: CreateDataPackageDetailInput!
    $condition: ModelDataPackageDetailConditionInput
  ) {
    createDataPackageDetail(input: $input, condition: $condition) {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataPackageDetail = /* GraphQL */ `
  mutation UpdateDataPackageDetail(
    $input: UpdateDataPackageDetailInput!
    $condition: ModelDataPackageDetailConditionInput
  ) {
    updateDataPackageDetail(input: $input, condition: $condition) {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataPackageDetail = /* GraphQL */ `
  mutation DeleteDataPackageDetail(
    $input: DeleteDataPackageDetailInput!
    $condition: ModelDataPackageDetailConditionInput
  ) {
    deleteDataPackageDetail(input: $input, condition: $condition) {
      id
      school_id
      package_name
      datasource
      dataset
      status
      last_sync_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGoogleSheetDetail = /* GraphQL */ `
  mutation CreateGoogleSheetDetail(
    $input: CreateGoogleSheetDetailInput!
    $condition: ModelGoogleSheetDetailConditionInput
  ) {
    createGoogleSheetDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGoogleSheetDetail = /* GraphQL */ `
  mutation UpdateGoogleSheetDetail(
    $input: UpdateGoogleSheetDetailInput!
    $condition: ModelGoogleSheetDetailConditionInput
  ) {
    updateGoogleSheetDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGoogleSheetDetail = /* GraphQL */ `
  mutation DeleteGoogleSheetDetail(
    $input: DeleteGoogleSheetDetailInput!
    $condition: ModelGoogleSheetDetailConditionInput
  ) {
    deleteGoogleSheetDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      googlesheet_link
      googlesheet_name
      googlesheet_id
      worksheet_name
      worksheet_id
      unique_field
      is_obtained
      interval_type
      last_success_sync_date
      last_failure_sync_date
      custom_interval
      is_cancelled
      custom_type
      custom_value
      table_name
      refresh_interval_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecurringSync = /* GraphQL */ `
  mutation CreateRecurringSync(
    $input: CreateRecurringSyncInput!
    $condition: ModelRecurringSyncConditionInput
  ) {
    createRecurringSync(input: $input, condition: $condition) {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecurringSync = /* GraphQL */ `
  mutation UpdateRecurringSync(
    $input: UpdateRecurringSyncInput!
    $condition: ModelRecurringSyncConditionInput
  ) {
    updateRecurringSync(input: $input, condition: $condition) {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecurringSync = /* GraphQL */ `
  mutation DeleteRecurringSync(
    $input: DeleteRecurringSyncInput!
    $condition: ModelRecurringSyncConditionInput
  ) {
    deleteRecurringSync(input: $input, condition: $condition) {
      id
      school_id
      success
      worksheet_id
      worksheet_name
      interval_type
      sync_for
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCountOfAPIHistory = /* GraphQL */ `
  mutation CreateCountOfAPIHistory(
    $input: CreateCountOfAPIHistoryInput!
    $condition: ModelCountOfAPIHistoryConditionInput
  ) {
    createCountOfAPIHistory(input: $input, condition: $condition) {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCountOfAPIHistory = /* GraphQL */ `
  mutation UpdateCountOfAPIHistory(
    $input: UpdateCountOfAPIHistoryInput!
    $condition: ModelCountOfAPIHistoryConditionInput
  ) {
    updateCountOfAPIHistory(input: $input, condition: $condition) {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCountOfAPIHistory = /* GraphQL */ `
  mutation DeleteCountOfAPIHistory(
    $input: DeleteCountOfAPIHistoryInput!
    $condition: ModelCountOfAPIHistoryConditionInput
  ) {
    deleteCountOfAPIHistory(input: $input, condition: $condition) {
      id
      school_id
      api_key
      dataset_type
      count
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataSetDetail = /* GraphQL */ `
  mutation CreateDataSetDetail(
    $input: CreateDataSetDetailInput!
    $condition: ModelDataSetDetailConditionInput
  ) {
    createDataSetDetail(input: $input, condition: $condition) {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataSetDetail = /* GraphQL */ `
  mutation UpdateDataSetDetail(
    $input: UpdateDataSetDetailInput!
    $condition: ModelDataSetDetailConditionInput
  ) {
    updateDataSetDetail(input: $input, condition: $condition) {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataSetDetail = /* GraphQL */ `
  mutation DeleteDataSetDetail(
    $input: DeleteDataSetDetailInput!
    $condition: ModelDataSetDetailConditionInput
  ) {
    deleteDataSetDetail(input: $input, condition: $condition) {
      id
      school_id
      data_set
      data_source
      purchased_date
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVeracrossDetail = /* GraphQL */ `
  mutation CreateVeracrossDetail(
    $input: CreateVeracrossDetailInput!
    $condition: ModelVeracrossDetailConditionInput
  ) {
    createVeracrossDetail(input: $input, condition: $condition) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVeracrossDetail = /* GraphQL */ `
  mutation UpdateVeracrossDetail(
    $input: UpdateVeracrossDetailInput!
    $condition: ModelVeracrossDetailConditionInput
  ) {
    updateVeracrossDetail(input: $input, condition: $condition) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVeracrossDetail = /* GraphQL */ `
  mutation DeleteVeracrossDetail(
    $input: DeleteVeracrossDetailInput!
    $condition: ModelVeracrossDetailConditionInput
  ) {
    deleteVeracrossDetail(input: $input, condition: $condition) {
      id
      school_id
      veracross_client_id
      veracross_client_secret
      scope
      school_route
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccountRequest = /* GraphQL */ `
  mutation CreateAccountRequest(
    $input: CreateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    createAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccountRequest = /* GraphQL */ `
  mutation UpdateAccountRequest(
    $input: UpdateAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    updateAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccountRequest = /* GraphQL */ `
  mutation DeleteAccountRequest(
    $input: DeleteAccountRequestInput!
    $condition: ModelAccountRequestConditionInput
  ) {
    deleteAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      Targets
      bulletPoints
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      Targets
      bulletPoints
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      Targets
      bulletPoints
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWebProfiles = /* GraphQL */ `
  mutation CreateWebProfiles(
    $input: CreateWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    createWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      last_sync_date
      disconnect
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWebProfiles = /* GraphQL */ `
  mutation UpdateWebProfiles(
    $input: UpdateWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    updateWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      last_sync_date
      disconnect
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWebProfiles = /* GraphQL */ `
  mutation DeleteWebProfiles(
    $input: DeleteWebProfilesInput!
    $condition: ModelWebProfilesConditionInput
  ) {
    deleteWebProfiles(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      metadata
      enabled
      last_sync_date
      disconnect
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOAuthCreds = /* GraphQL */ `
  mutation CreateOAuthCreds(
    $input: CreateOAuthCredsInput!
    $condition: ModelOAuthCredsConditionInput
  ) {
    createOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOAuthCreds = /* GraphQL */ `
  mutation UpdateOAuthCreds(
    $input: UpdateOAuthCredsInput!
    $condition: ModelOAuthCredsConditionInput
  ) {
    updateOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOAuthCreds = /* GraphQL */ `
  mutation DeleteOAuthCreds(
    $input: DeleteOAuthCredsInput!
    $condition: ModelOAuthCredsConditionInput
  ) {
    deleteOAuthCreds(input: $input, condition: $condition) {
      id
      account
      platform
      refresh_token
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIntegrationSyncHistory = /* GraphQL */ `
  mutation CreateIntegrationSyncHistory(
    $input: CreateIntegrationSyncHistoryInput!
    $condition: ModelIntegrationSyncHistoryConditionInput
  ) {
    createIntegrationSyncHistory(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const updateIntegrationSyncHistory = /* GraphQL */ `
  mutation UpdateIntegrationSyncHistory(
    $input: UpdateIntegrationSyncHistoryInput!
    $condition: ModelIntegrationSyncHistoryConditionInput
  ) {
    updateIntegrationSyncHistory(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const deleteIntegrationSyncHistory = /* GraphQL */ `
  mutation DeleteIntegrationSyncHistory(
    $input: DeleteIntegrationSyncHistoryInput!
    $condition: ModelIntegrationSyncHistoryConditionInput
  ) {
    deleteIntegrationSyncHistory(input: $input, condition: $condition) {
      id
      account
      platform
      profile_id
      source
      status
      createdAt
      detail
      updatedAt
      __typename
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFAQ = /* GraphQL */ `
  mutation CreateFAQ(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFAQ = /* GraphQL */ `
  mutation UpdateFAQ(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFAQ = /* GraphQL */ `
  mutation DeleteFAQ(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      question
      answer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFirebaseTokens = /* GraphQL */ `
  mutation CreateFirebaseTokens(
    $input: CreateFirebaseTokensInput!
    $condition: ModelFirebaseTokensConditionInput
  ) {
    createFirebaseTokens(input: $input, condition: $condition) {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFirebaseTokens = /* GraphQL */ `
  mutation UpdateFirebaseTokens(
    $input: UpdateFirebaseTokensInput!
    $condition: ModelFirebaseTokensConditionInput
  ) {
    updateFirebaseTokens(input: $input, condition: $condition) {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFirebaseTokens = /* GraphQL */ `
  mutation DeleteFirebaseTokens(
    $input: DeleteFirebaseTokensInput!
    $condition: ModelFirebaseTokensConditionInput
  ) {
    deleteFirebaseTokens(input: $input, condition: $condition) {
      id
      token
      user
      userDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDatasource = /* GraphQL */ `
  mutation CreateDatasource(
    $input: CreateDatasourceInput!
    $condition: ModeldatasourceConditionInput
  ) {
    createDatasource(input: $input, condition: $condition) {
      id
      data_provider
      data_source_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDatasource = /* GraphQL */ `
  mutation UpdateDatasource(
    $input: UpdateDatasourceInput!
    $condition: ModeldatasourceConditionInput
  ) {
    updateDatasource(input: $input, condition: $condition) {
      id
      data_provider
      data_source_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDatasource = /* GraphQL */ `
  mutation DeleteDatasource(
    $input: DeleteDatasourceInput!
    $condition: ModeldatasourceConditionInput
  ) {
    deleteDatasource(input: $input, condition: $condition) {
      id
      data_provider
      data_source_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotificationType = /* GraphQL */ `
  mutation CreateNotificationType(
    $input: CreateNotificationTypeInput!
    $condition: ModelNotificationTypeConditionInput
  ) {
    createNotificationType(input: $input, condition: $condition) {
      id
      notification_type
      code
      title
      notification_text
      content
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotificationType = /* GraphQL */ `
  mutation UpdateNotificationType(
    $input: UpdateNotificationTypeInput!
    $condition: ModelNotificationTypeConditionInput
  ) {
    updateNotificationType(input: $input, condition: $condition) {
      id
      notification_type
      code
      title
      notification_text
      content
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotificationType = /* GraphQL */ `
  mutation DeleteNotificationType(
    $input: DeleteNotificationTypeInput!
    $condition: ModelNotificationTypeConditionInput
  ) {
    deleteNotificationType(input: $input, condition: $condition) {
      id
      notification_type
      code
      title
      notification_text
      content
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserNotificationPreference = /* GraphQL */ `
  mutation CreateUserNotificationPreference(
    $input: CreateUserNotificationPreferenceInput!
    $condition: ModelUserNotificationPreferenceConditionInput
  ) {
    createUserNotificationPreference(input: $input, condition: $condition) {
      id
      user
      userDetails
      notificationType
      emailNotification
      inAppNotification
      webPushNotification
      createdAt
      updatedAt
      notificationTypeDetails {
        id
        notification_type
        code
        title
        notification_text
        content
        metadata
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateUserNotificationPreference = /* GraphQL */ `
  mutation UpdateUserNotificationPreference(
    $input: UpdateUserNotificationPreferenceInput!
    $condition: ModelUserNotificationPreferenceConditionInput
  ) {
    updateUserNotificationPreference(input: $input, condition: $condition) {
      id
      user
      userDetails
      notificationType
      emailNotification
      inAppNotification
      webPushNotification
      createdAt
      updatedAt
      notificationTypeDetails {
        id
        notification_type
        code
        title
        notification_text
        content
        metadata
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteUserNotificationPreference = /* GraphQL */ `
  mutation DeleteUserNotificationPreference(
    $input: DeleteUserNotificationPreferenceInput!
    $condition: ModelUserNotificationPreferenceConditionInput
  ) {
    deleteUserNotificationPreference(input: $input, condition: $condition) {
      id
      user
      userDetails
      notificationType
      emailNotification
      inAppNotification
      webPushNotification
      createdAt
      updatedAt
      notificationTypeDetails {
        id
        notification_type
        code
        title
        notification_text
        content
        metadata
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createUserNotification = /* GraphQL */ `
  mutation CreateUserNotification(
    $input: CreateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    createUserNotification(input: $input, condition: $condition) {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserNotification = /* GraphQL */ `
  mutation UpdateUserNotification(
    $input: UpdateUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    updateUserNotification(input: $input, condition: $condition) {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification(
    $input: DeleteUserNotificationInput!
    $condition: ModelUserNotificationConditionInput
  ) {
    deleteUserNotification(input: $input, condition: $condition) {
      id
      user
      userDetails
      userNotificationPreference
      title
      content
      message
      code
      metadata
      isRead
      emailSent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInitialSyncDetails = /* GraphQL */ `
  mutation CreateInitialSyncDetails(
    $input: CreateInitialSyncDetailsInput!
    $condition: ModelInitialSyncDetailsConditionInput
  ) {
    createInitialSyncDetails(input: $input, condition: $condition) {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInitialSyncDetails = /* GraphQL */ `
  mutation UpdateInitialSyncDetails(
    $input: UpdateInitialSyncDetailsInput!
    $condition: ModelInitialSyncDetailsConditionInput
  ) {
    updateInitialSyncDetails(input: $input, condition: $condition) {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInitialSyncDetails = /* GraphQL */ `
  mutation DeleteInitialSyncDetails(
    $input: DeleteInitialSyncDetailsInput!
    $condition: ModelInitialSyncDetailsConditionInput
  ) {
    deleteInitialSyncDetails(input: $input, condition: $condition) {
      id
      school_id
      data_provider
      data_source
      last_sync_date
      initial_sync_status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRules = /* GraphQL */ `
  mutation CreateRules(
    $input: CreateRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    createRules(input: $input, condition: $condition) {
      id
      school_id
      field_key
      data_source
      data_set
      rule_values
      created_by
      updated_by
      rule_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRules = /* GraphQL */ `
  mutation UpdateRules(
    $input: UpdateRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    updateRules(input: $input, condition: $condition) {
      id
      school_id
      field_key
      data_source
      data_set
      rule_values
      created_by
      updated_by
      rule_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRules = /* GraphQL */ `
  mutation DeleteRules(
    $input: DeleteRulesInput!
    $condition: ModelRulesConditionInput
  ) {
    deleteRules(input: $input, condition: $condition) {
      id
      school_id
      field_key
      data_source
      data_set
      rule_values
      created_by
      updated_by
      rule_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDaslValues = /* GraphQL */ `
  mutation CreateDaslValues(
    $input: CreateDaslValuesInput!
    $condition: ModelDaslValuesConditionInput
  ) {
    createDaslValues(input: $input, condition: $condition) {
      id
      field_key
      field_values
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDaslValues = /* GraphQL */ `
  mutation UpdateDaslValues(
    $input: UpdateDaslValuesInput!
    $condition: ModelDaslValuesConditionInput
  ) {
    updateDaslValues(input: $input, condition: $condition) {
      id
      field_key
      field_values
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDaslValues = /* GraphQL */ `
  mutation DeleteDaslValues(
    $input: DeleteDaslValuesInput!
    $condition: ModelDaslValuesConditionInput
  ) {
    deleteDaslValues(input: $input, condition: $condition) {
      id
      field_key
      field_values
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCsvDetail = /* GraphQL */ `
  mutation CreateCsvDetail(
    $input: CreateCsvDetailInput!
    $condition: ModelCsvDetailConditionInput
  ) {
    createCsvDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCsvDetail = /* GraphQL */ `
  mutation UpdateCsvDetail(
    $input: UpdateCsvDetailInput!
    $condition: ModelCsvDetailConditionInput
  ) {
    updateCsvDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCsvDetail = /* GraphQL */ `
  mutation DeleteCsvDetail(
    $input: DeleteCsvDetailInput!
    $condition: ModelCsvDetailConditionInput
  ) {
    deleteCsvDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      csv_name
      unique_field
      is_obtained
      is_cancelled
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPreviewDetail = /* GraphQL */ `
  mutation CreatePreviewDetail(
    $input: CreatePreviewDetailInput!
    $condition: ModelPreviewDetailConditionInput
  ) {
    createPreviewDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePreviewDetail = /* GraphQL */ `
  mutation UpdatePreviewDetail(
    $input: UpdatePreviewDetailInput!
    $condition: ModelPreviewDetailConditionInput
  ) {
    updatePreviewDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePreviewDetail = /* GraphQL */ `
  mutation DeletePreviewDetail(
    $input: DeletePreviewDetailInput!
    $condition: ModelPreviewDetailConditionInput
  ) {
    deletePreviewDetail(input: $input, condition: $condition) {
      id
      school_id
      created_by
      data_set
      show_fields
      hidden_fields
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQsdashboardMap = /* GraphQL */ `
  mutation CreateQsdashboardMap(
    $input: CreateQsdashboardMapInput!
    $condition: ModelQsdashboardMapConditionInput
  ) {
    createQsdashboardMap(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQsdashboardMap = /* GraphQL */ `
  mutation UpdateQsdashboardMap(
    $input: UpdateQsdashboardMapInput!
    $condition: ModelQsdashboardMapConditionInput
  ) {
    updateQsdashboardMap(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQsdashboardMap = /* GraphQL */ `
  mutation DeleteQsdashboardMap(
    $input: DeleteQsdashboardMapInput!
    $condition: ModelQsdashboardMapConditionInput
  ) {
    deleteQsdashboardMap(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQsdashboardDetail = /* GraphQL */ `
  mutation CreateQsdashboardDetail(
    $input: CreateQsdashboardDetailInput!
    $condition: ModelQsdashboardDetailConditionInput
  ) {
    createQsdashboardDetail(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      dashboard_name_public
      dashboard_description
      dashboard_type
      topic_id
      author
      tags
      createdAt
      updatedAt
      isFavoriteOf {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateQsdashboardDetail = /* GraphQL */ `
  mutation UpdateQsdashboardDetail(
    $input: UpdateQsdashboardDetailInput!
    $condition: ModelQsdashboardDetailConditionInput
  ) {
    updateQsdashboardDetail(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      dashboard_name_public
      dashboard_description
      dashboard_type
      topic_id
      author
      tags
      createdAt
      updatedAt
      isFavoriteOf {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteQsdashboardDetail = /* GraphQL */ `
  mutation DeleteQsdashboardDetail(
    $input: DeleteQsdashboardDetailInput!
    $condition: ModelQsdashboardDetailConditionInput
  ) {
    deleteQsdashboardDetail(input: $input, condition: $condition) {
      id
      school_id
      dashboard_id
      dashboard_name
      dashboard_name_public
      dashboard_description
      dashboard_type
      topic_id
      author
      tags
      createdAt
      updatedAt
      isFavoriteOf {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      id
      userId
      resourceType
      dashboard_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      id
      userId
      resourceType
      dashboard_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      id
      userId
      resourceType
      dashboard_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
