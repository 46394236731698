import { Auth } from "aws-amplify";
import {
  UPDATE_LOGIN,
  SET_PERMISSION,
  SET_ACCOUNT_REQUESTS,
  SET_ACCOUNT_DETAILS,
  LOGOUT,
  UPDATE_COMPANY_ACCOUNT,
  SET_VIEW_TYPE,
} from "./index";
import { SchoolBiApi } from "../../sdk/Api";
import { setUnauthorizedVisibility } from "./settingAction";

export const login = (userName, password) => {
  return async (dispatch, getState) => {
    let response = await Auth.signIn(userName, password);
    // const userInfo = await Auth.currentAuthenticatedUser();
    // console.log({ userInfo });
    if (
      (response?.challengeName === "NOMFA" ||
        response?.preferredMFA === "NOMFA") &&
      response?.attributes?.["custom:mfa"] === "true"
    ) {
      //Auth.updateUserAttribute(response,{"custom:mfa":False});
      return response;
    }
    if (
      response?.challengeName === "NEW_PASSWORD_REQUIRED" ||
      response?.challengeName === "MFA_SETUP" ||
      response?.challengeName === "SOFTWARE_TOKEN_MFA" ||
      response?.preferredMFA === "SOFTWARE_TOKEN_MFA"
    ) {
      return response;
    }

    const { idToken } = await Auth.currentSession();
    dispatch(setPermission(idToken.payload));
    let userAccountId = [];
    if ("cognito:groups" in response.signInUserSession.accessToken.payload) {
      userAccountId =
        response.signInUserSession.accessToken.payload["cognito:groups"];
      userAccountId.push(
        userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
      );
    }

    const groups =
      response?.signInUserSession?.accessToken?.payload?.["cognito:groups"];

    if (
      window.location.host.includes("stag") &&
      !groups?.includes("stage_users")
    ) {
      dispatch(setUnauthorizedVisibility(true));
    }
    dispatch(setLoginData(response.signInUserSession, response.attributes));
    return response;
  };
};

export const getCustomerAccountRequests = () => (dispatch) => {
  SchoolBiApi.getAccountRequests()
    .then((response) => {
      dispatch(setAccountRequests(response.data?.listAccountRequests?.items));
    })
    .catch((e) => console.log(e));
};

export const setLoginData = (token, userData) => {
  return {
    type: UPDATE_LOGIN,
    payload: token,
    data: userData,
  };
};

export const setPermission = (data) => {
  return {
    type: SET_PERMISSION,
    payload: data,
  };
};

export const setAccountRequests = (inputdata) => {
  return {
    type: SET_ACCOUNT_REQUESTS,
    payload: inputdata,
  };
};

export const getaccountDetails = (inputdata) => (dispatch) => {
  SchoolBiApi.getAccointApi(inputdata)
    .then((response) => {
      dispatch(setAccountDetails(response.data.getAccount));
    })
    .catch((err) => console.log(err));
};
export const setAccountDetails = (data) => {
  return {
    type: SET_ACCOUNT_DETAILS,
    payload: data,
  };
};

export const updateCompanyAccount = (data) => {
  return {
    type: UPDATE_COMPANY_ACCOUNT,
    payload: data,
  };
};
export const setViewType = (data) => {
  return {
    type: SET_VIEW_TYPE,
    payload: data,
  };
};
export const getPlanList = () => async (dispatch, getState) => {
  let nextToken = null;
  let list = [];
  do {
    let res = await SchoolBiApi.getPlanListApi();
    res?.data?.listPlans?.items?.map((item) => list.push(item));
    nextToken = res?.data?.listPlans?.nextToken;
  } while (nextToken);
  return list;
};
export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setTheme = (data) => {
  return {
    type: "SET_THEME",
    payload: data,
  };
};

export const setIsLoading = (data) => {
  return {
    type: "SET_IS_LOADING",
    payload: data,
  };
};
export const setGivenName = (data) => {
  return {
    type: "SET_GIVENNAME",
    payload: data,
  };
};
export const setFamilyName = (data) => {
  return {
    type: "SET_FAMILYNAME",
    payload: data,
  };
};
export const setUserData = (data) => {
  return {
    type: "SET_USERDATA",
    payload: data,
  };
};

export const setIsScopeNormalUser = (data) => ({
  type: "SET_IS_SCOPE_NORMALUSER",
  payload: data,
});

export const setSchoolData = (data) => ({
  type: "SET_SCHOOLDATA",
  payload: data,
});
