//** React imports */
import React, { useEffect, useState } from "react";
//** Amplify imports */
import { Auth } from "aws-amplify";
//** Common imports */
import DropDownMenu from "Common/DropDownMenu";
//** Ant imports */
import {
  Layout,
  Space,
  Typography,
  Button,
  Badge,
  Popover,
  Dropdown,
  Row,
  Breadcrumb,
} from "antd";
//** Ant Icon imports  */
import { BellOutlined, UserOutlined } from "@ant-design/icons";
//** Third Party imports */
import classnames from "classnames";
import { useTranslation } from "react-i18next";
//** Constant import */
import {
  MiscellaneousText,
  notificationModule,
  PopConfirmText,
} from "utility/constants";
import { useSelector } from "react-redux";
import NotificationPreferenceModal from "Common/Components/Notification/NotificationPreferenceModal";
import NotificationContent from "Common/Components/Notification/NotificationContent";
import Loader from "Common/Components/Loader/Loader";
import CustomModal from "Common/Components/CustomAntD/Modal";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

/**
 * Navbar component
 * @function Navbar
 * @return {<Navbar Content/>}
 */
const Navbar = (props) => {
  //** Destructing Ant Components */
  const { Header } = Layout;
  const { Title } = Typography;

  //** Destructing props */
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    viewType,
    toggleSettingsMenuOpen,
    location,
    switchAccountSelf,
  } = props;

  //** Destructing Hooks */
  const { t } = useTranslation();
  const history = useHistory();

  const { dashboardPublicName } = location?.state || {};

  //** Destructing Constants */
  const { yesText, viewingAs, userView, adminView } = MiscellaneousText;
  const { profileText } = PopConfirmText;
  const { Notifications_Text } = notificationModule;

  const [allBooleanState, setAllBooleanState] = useState({
    notificationModal: false,
  });
  const [loader, setLoader] = useState(false);
  const [breadcumbRoute, setBreadcumbRoute] = useState([]);

  const handleBooleanState = (key) => {
    setAllBooleanState((pS) => {
      pS[key] = !pS[key];
      return { ...pS };
    });
  };

  const handleSignOut = async () => {
    Auth.signOut()
      .then((data) => {
        this.props.logOut();
      })
      .catch((err) => {
        if (err?.code === "NotAuthorizedException") {
          localStorage.clear();
        }
        console.error(err);
      });
  };

  const {
    given_name,
    schoolData: { company },
  } = useSelector((state) => state.loginReducer);

  const notificationTitle = () => {
    return (
      <Row justify="space-between" className="notification-title">
        <Title level={5} className="popup-title">
          {t(Notifications_Text)} {loader && <Loader />}
        </Title>
      </Row>
    );
  };

  function itemRender(route) {
    return (
      <Title
        level={4}
        className={classNames("m-0 font-matter-bold", {
          "cursor-pointer": route?.path,
        })}
        onClick={() => {
          return route?.path ? history?.push(route?.path) : null;
        }}
      >
        {route?.breadcrumbName}
      </Title>
    );
  }

  useEffect(() => {
    if (window.location.pathname?.includes("/qs-dashboard/")) {
      return setBreadcumbRoute(() => [
        {
          breadcrumbName: dashboardPublicName,
        },
      ]);
    } else if (window.location.pathname?.includes("/qs-dashboard")) {
      return setBreadcumbRoute(() => [
        {
          breadcrumbName: company,
        },
      ]);
    }
  }, [company, dashboardPublicName]);

  return (
    <>
      <Header className="site-layout-background p-0">
        <div
          className={classnames("search-wrapper", {
            searchBarHandler: "search-container header-wrapper",
          })}
        >
          {breadcumbRoute?.length > 0 ? (
            <div className="d-flex align-center w-100 p-4">
              <Breadcrumb
                routes={breadcumbRoute}
                itemRender={itemRender}
                className="header-breadcumb"
              />
            </div>
          ) : null}

          <Space>
            {/* Search bar container Start */}
            {/* Dev Comment :- */}
            {/* <div className="search-container position-relative">
            {screens.xs || screens.sm ? (
              searchView ? (
                <Search
                  className="search-view-container"
                  prefix={<SearchOutlined />}
                  placeholder={
                    "Search names here... ( Press 'enter' to search)"
                  }
                  suffix={
                    <CloseOutlined onClick={() => toggleSearchView(false)} />
                  }
                />
              ) : (
                <div className="search-input-wrapper">
                  <SearchOutlined
                    className="fs-18"
                    onClick={() => toggleSearchView(true)}
                  />
                </div>
              )
            ) : (
              <Search
                placeholder="Search"
                className="rounded-0 search-large-screen"
              />
            )}
          </div> */}
            {/* Search bar container End */}
            {/* Badge Icon Container Start */}
            <div className={classnames("d-flex", { searchView: "d-none" })}>
              <Popover
                placement="bottomRight"
                title={notificationTitle}
                content={
                  <NotificationContent
                    setLoader={setLoader}
                    handleBooleanState={handleBooleanState}
                  />
                }
                trigger="hover"
                overlayClassName="notification-popover"
                overlayStyle={{
                  width: 500,
                  maxHeight: 400,
                  overflowY: "scroll",
                }}
              >
                {/* <Button
                  className="tertiary-color border-0 p-0"
                  icon={ */}
                <Badge color="#FFC90A" dot>
                  <BellOutlined
                    className={
                      "d-flex justify-content-center cursor-pointer fs-20 tertiary-color"
                    }
                  />
                </Badge>
                {/* }
                /> */}
              </Popover>
            </div>
            {/* Badge Icon Container End */}

            {/* Dropdown menu of the navbar Start */}
            <Dropdown
              overlay={
                <DropDownMenu
                  handleSignOut={handleSignOut}
                  path={location?.pathname}
                />
              }
              trigger={["click"]}
              placement={"bottomLeft"}
            >
              <div className="d-flex align-center gap-2">
                {/* Avatar of the dropdown */}
                <Button
                  className="bg-silver"
                  shape="circle"
                  type="text"
                  size="large"
                  id="settings-menu-trigger-btn"
                  data-tour="tour__settings"
                  onBlur={() => {
                    setTimeout(() => toggleSettingsMenuOpen(false), 200);
                  }}
                  onFocus={() => toggleSettingsMenuOpen(true)}
                >
                  <h3 className="color-black font-bold fs-18 mb-0">
                    {given_name !== null ? (
                      given_name?.[0]?.toUpperCase()
                    ) : (
                      <UserOutlined />
                    )}
                  </h3>
                </Button>
                {/* Name Container Start */}
                <span
                  className={classnames(
                    "font-bold color-black fs-18 cursor-pointer",
                    {
                      searchView: "d-none",
                    }
                  )}
                >
                  {/* Hi, */}
                  {given_name !== null ? given_name : "loading...."}
                </span>
                {/* Name Container End */}
              </div>
            </Dropdown>
            {/* Dropdown menu of the navbar End */}
          </Space>
        </div>
      </Header>
      <CustomModal
        visible={allBooleanState?.notificationModal}
        closable={true}
        centered
        width="70%"
        onCancel={() => handleBooleanState("notificationModal")}
        onOk={() => handleBooleanState("notificationModal")}
        className="notification-modal"
        bodyStyle={{
          minHeight: 600,
          maxHeight: 600,
          overflow: "scroll",
        }}
        footer={null}
      >
        <NotificationPreferenceModal />
      </CustomModal>
    </>
  );
};

export default Navbar;
