//** React Imports */
import React, { useCallback, useEffect, useState, useRef } from "react";
//** Ant Components Imports */
import {
  Row,
  Typography,
  Tabs,
  Carousel,
  Image,
  Col,
  Button,
  message,
  Divider,
} from "antd";
//** Constants Imports */
import { buttonText, ManageFiles_Constants } from "utility/constants";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Helper Component Imports */
import RecentFiles from "./RecentFiles";
import UploadFiles from "./UploadFiles";
//** Styling Imports  */
import "./ManageFiles.less";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import UploadedMultiWorksheet from "./UploadedMultiWorksheet";
import ConfigureTable from "./ConfigureTable";
import UploadedRefeshInterval from "./UploadedRefeshInterval";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import { Images } from "assets/images/Images";
import ManageFileSDK from "sdk/ManageFileSDK";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import UploadedArchieve from "./UploadedArchieve";
import { useLocation } from "react-router-dom";
import CustomModal from "Common/Components/CustomAntD/Modal";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import CSVFiles from "./CSVFiles";
import NotPurchasedModal from "Common/Components/NotPurchasedModal/NotPurchasedModal";

const ManageFiles = () => {
  const carouselRef = useRef();

  const location = useLocation();

  const SUBKEY = location?.state?.SUBKEY || null;
  //** Destructing Ant Components */
  const { Title } = Typography;
  const { TabPane } = Tabs;
  //** Destructing Hooks & Constants */
  const { t } = useTranslation();
  const { OK_Btn, Cancel, Done_Btn } = buttonText;
  const {
    ManageFiles_Title,
    Uploaded_Sheet_Text,
    Save_as_google_Text,
    Google_Sheet_One_Text,
    Google_Sheet_Two_Text,
    Google_Sheet_Three_Text,
    Google_Sheet_Four_Text,
  } = ManageFiles_Constants;

  const {
    WorksheetUploadedSvg,
    FileErrorSvg,
    ScreenshotFileSvg,
    NoCustomFile,
  } = Images?.ManageFiles;

  const { groups, userId } = useSelector((state) => state.loginReducer); // Getting group from login reducer
  const accountId = groups.length > 0 ? groups[0] : null; // Getting AccountID from Group

  const [modalVisibility, setModalVisibility] = useState({
    uploadMultiSheetVisible: false,
    isNotGoogleSheetModal: false,
    uploadCSVModal: false,
  }); // Handles All Modal Visibility
  const [workSheetUploaded, setWorkSheetUploaded] = useState(false); // Handle SuccessFull Modal Visibility
  const [workSheetUploadedContent, setWorkSheetUploadedContent] =
    useState(null); // Handle SuccessFull Modal Visibility
  const [archieveFilesVisibility, setArchieveFilesVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlideValue, setCurrentSlideValue] = useState(0);
  const [worksheetConfigError, setWorksheetConfigError] = useState(false);
  const [currentActiveKey, setCurrentActiveKey] = useState("upload-files");
  const [csvKey, setCSVKey] = useState(null);
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  const [isOpen, setIsOpen] = useState(false);

  const { isManageFilesVisibility, currentConfigureRenderKey } = useSelector(
    (state) => state.settingReducer
  );

  const initialWorkSheetConfig = {
    uploadUrl: null,
    allWorkSheetList: [],
    selectedWorksheet: null,
    worksheetDetails: {},
    tableConfigurationData: {},
    uniqueId: null,
    uniqueField: null,
    googleSheetName: null,
    allWorksheetId: {},
    isTryAgain: false,
  };

  const initialRepeatIntervalForm = {
    interval_type: "DAILY",
  };

  const [workSheetConfig, setWorkSheetConfig] = useState(
    initialWorkSheetConfig
  );

  const [repeatIntervalForm, setRepeatIntervalForm] = useState(
    initialRepeatIntervalForm
  );

  const handleModalVisibility = useCallback((modalName) => {
    setModalVisibility((pS) => {
      pS[modalName] = !pS[modalName];
      return { ...pS };
    });
  }, []);

  const handleWorkSheetConfig = (key, value) => {
    return setWorkSheetConfig((pS) => {
      pS[key] = value;
      return { ...pS };
    });
  };

  const goToSlide = (index) => {
    setCurrentSlideValue(index);
    carouselRef.current.goTo(index);
  };

  const onChange = async (currentSlide) => {
    if (currentSlide === 2) {
      const { tableConfigurationData } = workSheetConfig;
      Object.values(tableConfigurationData).forEach((fields) =>
        Object.values(fields).forEach((field) => {
          const { user_attribute, start_date, end_date } = field;
          if (user_attribute === "TIMEBOUND") {
            if (!start_date || !end_date) {
              setWorksheetConfigError(true);
              message.error("Please select time interval for timebound fields");
            } else {
              setWorksheetConfigError(false);
            }
          }
        })
      );
    }

    if (
      currentSlide === 1 &&
      Object.values(
        workSheetConfig?.worksheetDetails?.[workSheetConfig?.selectedWorksheet]
      ).length === 0
    ) {
      const selectedWorksheet = workSheetConfig?.selectedWorksheet;

      if (!selectedWorksheet) return;

      const body = {
        sheet_name: selectedWorksheet,
        googleSheetLink: workSheetConfig?.uploadUrl,
      };

      setIsLoading(true);
      await ManageFileSDK.getWorksheetFields(body)
        .then((data) => {
          setWorkSheetConfig((pS) => {
            pS["worksheetDetails"][selectedWorksheet] =
              data?.[selectedWorksheet] || {};

            pS["allWorksheetId"][selectedWorksheet] = data?.worksheetID;

            return { ...pS };
          });
        })
        .finally(() => setIsLoading(false));
    }

    setCurrentSlideValue(currentSlide);
    goToSlide(currentSlide);
  };

  const handleFinalStates = () => {
    setIsLoading(false);
    setCurrentSlideValue(0);
    goToSlide(0);
    setWorkSheetConfig(initialWorkSheetConfig);
    setRepeatIntervalForm(initialRepeatIntervalForm);
    setWorksheetConfigError(false);
  };

  const handleUploadMultiSheetVisible = () => {
    const body = {
      id: workSheetConfig?.uniqueId,
      is_cancelled: true,
    };
    handleModalVisibility("uploadMultiSheetVisible");
    setIsLoading(true);
    ManageFileSDK.isCancelledHandler(body)
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => handleFinalStates());
  };

  const postUploadHelper = (res, isTryAgain = false) => {
    if (res?.status !== 200) {
      if (res?.message) {
        setApiResultModal(() => ({
          message: res?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      } else {
        handleModalVisibility("isNotGoogleSheetModal");
      }
      return;
    }

    const allWorkSheet = Object.keys(res?.worksheetDetails)?.map((item) => ({
      value: item,
      label: capitalize(item?.toString()),
    }));

    setWorkSheetConfig((pS) => {
      pS["allWorkSheetList"] = allWorkSheet;
      pS["worksheetDetails"] = res?.worksheetDetails;
      pS["googleSheetName"] = res?.googlesheet_name;
      pS["googleSheetId"] = res?.googlesheet_id;

      if (!pS?.uniqueId) {
        pS["uniqueId"] = res?.uniqueId;
      }

      if (pS?.selectedWorksheet === null) {
        pS["selectedWorksheet"] = Object.keys(res?.worksheetDetails)[0];
      }
      if (isTryAgain) {
        pS["isTryAgain"] = isTryAgain;
      }

      return { ...pS };
    });

    setCurrentSlideValue(0);
    handleModalVisibility("uploadMultiSheetVisible");
  };

  const uploadFileHandler = async () => {
    if (!workSheetConfig?.uploadUrl) {
      setApiResultModal(() => ({
        message: "Please provide a sheet link",
        isApiResultModelOpen: true,
        type: "failure",
      }));

      return;
    }
    if (
      !workSheetConfig?.uploadUrl.startsWith(
        "https://docs.google.com/spreadsheets"
      )
    ) {
      setApiResultModal(() => ({
        message: "Please provide a valid google spreadsheets link",
        isApiResultModelOpen: true,
        type: "failure",
      }));
      return;
    }
    const body = {
      accountId: accountId,
      googleSheetLink: workSheetConfig?.uploadUrl,
      createdBy: userId,
    };

    setIsLoading(true);
    await ManageFileSDK?.googleSheetLinkHandler(body)
      .then((res) => postUploadHelper(res))
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => setIsLoading(false));
  };

  const tryAgainHandler = async (item) => {
    const { id, googlesheet_link, worksheet_name } = item;

    setWorkSheetConfig((pS) => {
      pS["uploadUrl"] = googlesheet_link;
      pS["selectedWorksheet"] = worksheet_name;
      pS["uniqueId"] = id;

      return { ...pS };
    });

    const body = {
      accountId: accountId,
      uniqueId: id,
      googleSheetLink: googlesheet_link,
    };

    setIsLoading(true);
    await ManageFileSDK.cancelledGoogleSheetHandler(body)
      .then((res) => postUploadHelper(res, true))
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => setIsLoading(false));
  };

  const handleDeleteGoogleSheetHandler = (record, func) => {
    setIsLoading(true);
    ManageFileSDK.deleteGoogleSheetDetailHandler(record)
      .then(() => func())
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => {
        setIsLoading((pS) => !pS);
        setWorkSheetUploaded((pS) => !pS);
        setWorkSheetUploadedContent(
          "This process will take some time to complete"
        );
      });
  };

  const terminatefileHandler = async (item) => {
    const { id, worksheet_id, school_id, googlesheet_id } = item;
    const body = {
      id,
      school_id,
      worksheet_id,
      googlesheet_id,
    };

    setIsLoading(true);
    await ManageFileSDK.terminateSheetHandler(body)
      .then((res) => res)
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => setIsLoading(false));
  };

  const handleTableConfiguration = (type) => {
    if (worksheetConfigError) {
      message.error("Please select time interval for timebound fields");
      return;
    }

    setIsLoading(true);

    const {
      tableConfigurationData,
      uniqueField,
      uniqueId,
      uploadUrl,
      selectedWorksheet,
      googleSheetName,
      allWorksheetId,
      googleSheetId,
      isTryAgain,
    } = workSheetConfig;

    const body = {
      ...tableConfigurationData,
      ...repeatIntervalForm,
      unique_field: uniqueField,
      id: uniqueId,
      googlesheet_link: uploadUrl,
      worksheet: selectedWorksheet,
      account: accountId,
      googlesheet_name: googleSheetName,
      worksheet_id: allWorksheetId[selectedWorksheet],
      googlesheet_id: googleSheetId,
      isTryAgain,
    };

    if (type === "skip") {
      body["interval_type"] = "NONE";
    }

    handleModalVisibility("uploadMultiSheetVisible");
    ManageFileSDK.tableConfigurationHandler(body)
      .then(() => {
        setWorkSheetUploaded(true);
      })
      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => handleFinalStates());
  };

  const getCSVFields = async (key) => {
    const input = {
      school_id: accountId,
      key: key,
      sheet_name: key?.split("/")[2]?.split(".")[0],
    };
    setCSVKey(key);
    return await ManageFileSDK.getCSVFields(input)
      .then((res) => {
        if (res?.statusCode === 409) {
          const body = JSON.parse(res?.body);
          throw new Error(body?.message);
        }
        setWorkSheetConfig((pS) => {
          pS["worksheetDetails"] = res;
          pS["selectedWorksheet"] = input?.sheet_name;
          return { ...pS };
        });

        handleModalVisibility("uploadCSVModal");
        return res;
      })
      .catch((e) => {
        console.log(e);
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      });
  };

  const uploadCSVDetails = async () => {
    const { tableConfigurationData, uniqueField } = workSheetConfig;
    const body = {
      ...tableConfigurationData,
      unique_field: uniqueField,
      account: accountId,
      csv_name: csvKey?.split("/")[2]?.split(".")[0],
      createdBy: userId,
      s3_file_path: csvKey,
    };
    setIsLoading(true);
    return await ManageFileSDK.setCSVFields(body)
      .then((res) => {
        handleModalVisibility("uploadCSVModal");
        if (!(res?.status === 200)) {
          throw new Error(res?.message);
        }
        return res;
      })
      .catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      )
      .finally(() => setIsLoading(false));
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={
          className + (currentSlideValue === 2 ? " last-slick-slide" : "")
        }
        style={{
          ...style,
          display: "block",
          top: "unset",
          bottom: "-10%",
          left: "90%",
        }}
        onClick={onClick}
      >
        {currentSlideValue === 2 ? (
          <Button
            type="primary"
            onClick={() => handleTableConfiguration()}
            style={{
              marginTop: 16,
            }}
          >
            {t(Done_Btn)}
          </Button>
        ) : null}
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
      <div
        className={
          className + (currentSlideValue === 2 ? " last-slick-slide" : "")
        }
        style={{
          ...style,
          display: "block",
          top: "unset",
          bottom: "-10%",
          right: 0,
        }}
        onClick={onClick}
      >
        {currentSlideValue === 2 ? null : (
          <ArrowRightOutlined
            style={{ color: "black", fontSize: 25, marginTop: 16 }}
          />
        )}
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  //** Tab Pane Data */
  const tabPaneData = [
    // {
    //   key: "upload-files",
    //   tab: "Upload New",
    //   component: (
    //     <UploadFiles
    //       uploadFileHandler={uploadFileHandler}
    //       workSheetConfig={workSheetConfig}
    //       handleWorkSheetConfig={handleWorkSheetConfig}
    //       getCSVFields={getCSVFields}
    //     />
    //   ),
    // },
    {
      key: "recent-files",
      tab: "Recently Uploaded",
      component: (
        <RecentFiles
          uploadFileHandler={uploadFileHandler}
          workSheetConfig={workSheetConfig}
          handleWorkSheetConfig={handleWorkSheetConfig}
          tryAgainHandler={tryAgainHandler}
          deleteBtnHandler={handleDeleteGoogleSheetHandler}
          currentActiveKey={currentActiveKey}
          terminatefileHandler={terminatefileHandler}
          SUBKEY={SUBKEY}
          setWorkSheetUploaded={setWorkSheetUploaded}
        />
      ),
    },
    {
      key: "csv-files",
      tab: "CSV Files",
      component: <CSVFiles />,
    },
  ];

  // useEffect(() => {
  //   if (!isNormalUser) {
  //     setTabPaneData((pS) => {
  //       if (!pS.some((item) => item.key === "upload-files")) {
  //         return [
  //           {
  //             key: "upload-files",
  //             tab: "Upload New",
  //             component: (
  //               <UploadFiles
  //                 uploadFileHandler={uploadFileHandler}
  //                 workSheetConfig={workSheetConfig}
  //                 handleWorkSheetConfig={handleWorkSheetConfig}
  //               />
  //             ),
  //           },
  //           ...pS,
  //         ];
  //       }

  //       return pS;
  //     });
  //   }
  // }, [isNormalUser]);

  useEffect(() => {
    // if (!isNormalUser) {
    // } else {
    //   setCurrentActiveKey("recent-files");
    // }
    setCurrentActiveKey(SUBKEY ? "recent-files" : "upload-files");
  }, [SUBKEY]);

  useEffect(() => {
    if (
      currentConfigureRenderKey === "custom-files" &&
      isManageFilesVisibility
    ) {
      setIsOpen(true);
    }
  }, [currentConfigureRenderKey, isManageFilesVisibility]);

  return (
    <>
      {isLoading && <div className="loading"></div>}
      {isManageFilesVisibility ? (
        <>
          <Image src={NoCustomFile} preview={false} className="filter-effect" />
          <NotPurchasedModal
            text={"Custom Files"}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </>
      ) : (
        <Row className="manage-files-container flex-col ">
          <Col>
            {/* <Row>
            <Title level={4} className="manage-files-heading">
              {t(ManageFiles_Title)}
              {/* <Button className="archieve-btn" type="primary">
                {t(Archieve_Files_Btn)}
              </Button> /}
            </Title>
          </Row> */}
            <Row>
              {archieveFilesVisibility ? (
                <>
                  <UploadedArchieve
                  // allUploadedGoogleSheets={allUploadedGoogleSheets}
                  // handleRefreshIntervalModal={handleRefreshIntervalModal}
                  // deleteBtnHandler={deleteBtnHandler}
                  // fetchAccountDetails={fetchAccountDetails}
                  // dynamicRowClassName={dynamicRowClassName}
                  // Loading={Loading}
                  // handleFilePreview={handleFilePreview}
                  />
                </>
              ) : (
                // <Tabs
                //   defaultActiveKey={"upload-files"}
                //   tabPosition="top"
                //   className="school-details organization w-100 "
                //   activeKey={currentActiveKey}
                //   onChange={(e) => setCurrentActiveKey(e)}
                // >
                //   {tabPaneData.map((tabPaneItem) => (
                //     <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                //       {tabPaneItem?.component}
                //     </TabPane>
                //   ))}
                // </Tabs>
                <>
                  <Col span={24}>
                    <Title level={4} className="manage-files-heading">
                      Upload New
                    </Title>
                    <UploadFiles
                      uploadFileHandler={uploadFileHandler}
                      workSheetConfig={workSheetConfig}
                      handleWorkSheetConfig={handleWorkSheetConfig}
                      getCSVFields={getCSVFields}
                    />
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <Title level={4} className="manage-files-heading">
                      Recently Uploaded
                    </Title>

                    {/* <Tabs
                defaultActiveKey={"upload-files"}
                tabPosition="top"
                className="school-details organization w-100 "
                activeKey={currentActiveKey}
                onChange={(e) => setCurrentActiveKey(e)}
              >
                {tabPaneData.map((tabPaneItem) => (
                  <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                    {tabPaneItem?.component}
                  </TabPane>
                ))}
              </Tabs> */}
                    <RecentFiles
                      uploadFileHandler={uploadFileHandler}
                      workSheetConfig={workSheetConfig}
                      handleWorkSheetConfig={handleWorkSheetConfig}
                      tryAgainHandler={tryAgainHandler}
                      deleteBtnHandler={handleDeleteGoogleSheetHandler}
                      currentActiveKey={currentActiveKey}
                      terminatefileHandler={terminatefileHandler}
                      SUBKEY={SUBKEY}
                      setWorkSheetUploaded={setWorkSheetUploaded}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      )}

      <CustomModal
        visible={modalVisibility?.uploadMultiSheetVisible}
        closable={true}
        width={"75%"}
        centered
        onCancel={() => handleUploadMultiSheetVisible()}
        footer={null}
        bodyStyle={{
          height: "600px",
          overflow: "hidden",
          borderRadius: 10,
        }}
        className="carousel-container configure-table-modal refresh-interval-modal "
      >
        <Carousel
          afterChange={onChange}
          arrows
          {...settings}
          className="uploadedmultisheet-container"
          ref={carouselRef}
          destroyOnClose={true}
        >
          <div>
            <UploadedMultiWorksheet
              multiWorksheetOption={workSheetConfig?.allWorkSheetList}
              handleWorkSheetConfig={handleWorkSheetConfig}
              selectedWorksheet={workSheetConfig?.selectedWorksheet}
            />
          </div>
          <div>
            <ConfigureTable
              columns={
                workSheetConfig?.worksheetDetails[
                  workSheetConfig?.selectedWorksheet
                ]
              }
              setWorkSheetConfig={setWorkSheetConfig}
              handleWorkSheetConfig={handleWorkSheetConfig}
              uniqueField={workSheetConfig?.uniqueField}
              isLoading={isLoading}
            />
          </div>
          <div>
            <UploadedRefeshInterval
              repeatIntervalForm={repeatIntervalForm}
              setRepeatIntervalForm={setRepeatIntervalForm}
              handleTableConfiguration={handleTableConfiguration}
            />
          </div>
        </Carousel>
      </CustomModal>

      <CustomModal
        visible={modalVisibility?.isNotGoogleSheetModal}
        closable={true}
        centered
        onCancel={() => handleModalVisibility("isNotGoogleSheetModal")}
        width={"55%"}
        footer={null}
      >
        <Row justify="center">
          <Image src={FileErrorSvg} preview={false}></Image>
          <Title level={5} className="fs-18 outer-space-color font-bold ">
            {t(Uploaded_Sheet_Text)}
          </Title>

          <Col span={12}>
            <Title level={2}>{t(Save_as_google_Text)}</Title>
            <div className="d-flex gap-4 flex-col">
              <Typography level={5} className="fs-16 outer-space-color">
                {t(Google_Sheet_One_Text)}
              </Typography>
              <Typography level={5} className="fs-16 outer-space-color">
                {t(Google_Sheet_Two_Text)}
              </Typography>
              <Typography level={5} className="fs-16 outer-space-color">
                {t(Google_Sheet_Three_Text)}
              </Typography>
              <Typography level={5} className="fs-16 outer-space-color">
                {t(Google_Sheet_Four_Text)}
              </Typography>
            </div>
          </Col>
          <Col span={12} className="d-flex justify-content-end">
            <Image src={ScreenshotFileSvg} preview={false}></Image>
          </Col>
        </Row>
        <Row>
          <Button
            className="secondary-btn"
            onClick={() => handleModalVisibility("isNotGoogleSheetModal")}
          >
            {t(Cancel)}
          </Button>
          <Button
            type="primary"
            className="px-3"
            onClick={() => handleModalVisibility("isNotGoogleSheetModal")}
          >
            {t(OK_Btn)}
          </Button>
        </Row>
      </CustomModal>

      <CustomModal
        visible={modalVisibility?.uploadCSVModal}
        closable={true}
        width={"75%"}
        centered
        bodyStyle={{
          overflow: "hidden",
          borderRadius: 10,
          padding: 0,
        }}
        className="carousel-container configure-table-modal refresh-interval-modal"
        onCancel={() => handleModalVisibility("uploadCSVModal")}
        onOk={() => uploadCSVDetails()}
        confirmLoading={isLoading}
      >
        <div>
          <ConfigureTable
            columns={
              workSheetConfig?.worksheetDetails[
                workSheetConfig?.selectedWorksheet
              ]
            }
            setWorkSheetConfig={setWorkSheetConfig}
            handleWorkSheetConfig={handleWorkSheetConfig}
            uniqueField={workSheetConfig?.uniqueField}
            isLoading={isLoading}
          />
        </div>
      </CustomModal>

      <SuccessfulModal
        successImage={WorksheetUploadedSvg}
        isShowSuccessfulModal={workSheetUploaded}
        setIsShowSuccessfulModal={setWorkSheetUploaded}
        modalText={
          workSheetUploadedContent ||
          "This process will take a few mins to upload file. Once get uploaded successfully, you can view this file in the “Recent Uploaded tab“"
        }
        width={550}
        okBtnClassNames={"mt-5"}
        okBtnClickHandler={() => setWorkSheetUploadedContent(null)}
      />

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default ManageFiles;
