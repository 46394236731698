//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Design Imports */
import {
  Row,
  Tabs,
  List,
  Tooltip,
  Button,
  Col,
  Input,
  Menu,
  Dropdown,
  Typography,
  Image,
  Popconfirm,
  Form,
  Select,
  message,
  Tag,
} from "antd";
//** Ant Design Icon Import */
import {
  MoreOutlined,
  SearchOutlined,
  RedoOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
//** Images Import */
import InviteUserDetails from "assets/images/InviteUserDetails.svg";

//** Third Party Imports */
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
//** SDK Imports */
import sdk from "sdk/Accounts";
//** Redux Imports */
import { useSelector } from "react-redux";
//** Constant Imports */
import {
  buttonText,
  messageText,
  MiscellaneousText,
  PopConfirmText,
  schoolSettingsText,
  userDetailsText,
} from "utility/constants";
//** Common Method Imports */
import {
  apiExecuter,
  fetchAccountDetails,
  get_attribute,
} from "utility/commonMethods";
//** Skeleton Imports */
import SkeletonBlock from "Common/Skeleton/SkeletonBlock.js";
import InviteModal from "Common/Components/InviteModal/InviteModal";
import { IntegrationSDK } from "sdk/IntegrationSDK";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import CustomModal from "Common/Components/CustomAntD/Modal";
import { Images } from "assets/images/Images";
import SchoolDetailsSDK from "sdk/SchoolDetailsSDK";
import Accounts from "sdk/Accounts";
import { startCase } from "lodash";

export default function UserDetails() {
  /** Destructing Ant Components */
  const { TabPane } = Tabs;
  //** Destructing Hooks */
  const { t } = useTranslation();
  const [selectAUserForm] = Form.useForm();
  const [changeUserRoleForm] = Form.useForm();
  const { Title } = Typography;

  const initialUserRole = [
    { label: "School Admin", value: "SchoolAdmin" },
    { label: "Normal User", value: "normal_user" },
    { label: "Report User", value: "ReportUser" },
  ];

  /**
   * @useState for handling user details
   */
  const [Users, setUsers] = useState([]);
  const [isInviteModelOpen, setIsInviteModelOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [changeUserRoleModalOpen, setChangeUserRoleModalOpen] = useState(false);
  const [loader, setIsLoader] = useState(false);
  const [userToBeDelete, setUserToBeDelete] = useState({});
  const [searchText, setsearchText] = useState("");
  // eslint-disable-next-line
  const [searchTextReq, setsearchTextReq] = useState("");
  const [userRequests, setuserRequests] = useState([]);
  const [acct, setAcct] = useState(null);
  const [selectAuser, setSelectAuser] = useState([]);
  const [userRole, setUserRole] = useState(initialUserRole);
  const [swapRoleDetails, setSwapRoleDetails] = useState({
    emailId: null,
    old_user_role: null,
  });
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  //** Getting data from Reducer */
  const { isLoading, groups, isScopeNormalUser } = useSelector(
    (state) => state.loginReducer
  );
  const { AssignNewImg } = Images?.ModalImg;

  const isNormalUser =
    groups?.includes("normal_user") ||
    groups?.includes("ReportUser") ||
    isScopeNormalUser;

  //** Destructing Constants */
  const { InviteTeamMemberText_P1, InviteTeamMemberText_P2 } = userDetailsText;
  const { Invite_Team_Title, Delete_User_Text, Approve_Text, Deny_Text } =
    buttonText;
  const { users, Requests_Text, yesText } = MiscellaneousText;
  const { deleteUserText } = PopConfirmText;
  const { Search_Team_Member_Text } = schoolSettingsText?.userDetailsText;
  const { Failed_Approve_Text, Approve_MText, Denied_MText } = messageText;

  /**
   * @function setUsersData
   * Setting the users data
   */
  const setUsersData = () => {
    const parseUsers = acct?.users?.map((item) => JSON.parse(item));
    const userData = parseUsers?.map((item) => ({
      ...item,
      Useremail: `${get_attribute(item?.Attributes, "email")}`,
      UserFullName: `${get_attribute(
        item?.Attributes,
        "given_name"
      )} ${get_attribute(item?.Attributes, "family_name")}`,
    }));
    const derivedUsersValue = userData
      ?.map(({ UserFullName, Useremail }) => ({
        label: UserFullName,
        value: Useremail,
      }))
      .filter(({ value }) => value !== acct?.email);

    setSelectAuser(derivedUsersValue);
    setUsers(userData);
  };

  /** Checking all users requests
   * @function setUserRequestData
   */
  const setUserRequestData = () => {
    let requests = acct?.requests?.items;
    requests ? setuserRequests(requests) : setuserRequests([]);
  };

  /**
   * Fetching account for updating
   * @function handleFetchAccountDetails
   */
  const handleFetchAccountDetails = () => fetchAccountDetails(setAcct);

  /**
   * Approves request of the user
   * On approving request =>  deleting it
   * @function approveRequest
   * @param {*} id
   */
  const approveOrDenyUserRequest = async (item, flag) => {
    if (item) {
      const bodyData = {
        schoolName: acct?.company,
        request: flag,
        accountRequestId: item?.id,
      };
      await apiExecuter(async () => {
        await sdk
          .approveOrDenyRequest(bodyData)
          .then(() => {
            setApiResultModal(() => ({
              message: t(flag ? Approve_MText : Denied_MText),
              isApiResultModelOpen: true,
              type: "success",
            }));
            return;
          })
          .catch(() => {
            setApiResultModal(() => ({
              message: t(Failed_Approve_Text),
              isApiResultModelOpen: true,
              type: "failure",
            }));
            return;
          })
          .finally(() => {
            handleFetchAccountDetails();
          });
      });
    }
  };

  /**
   * @function getSkeletonBlock
   * @return  {SkeletonBlock}
   */
  const getSkeletonBlock = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <SkeletonBlock />
      </div>
    );
  };

  const updateSchoolProfile = async () => {
    setIsLoader(true);
    const { userEmail } = selectAUserForm.getFieldsValue();
    const input = {
      id: acct?.id,
      email: userEmail,
    };
    await SchoolDetailsSDK.updateSchoolMail(input);
    await deleteRequest(userToBeDelete, true);
    setIsDeleteConfirmModalOpen(false);
    setIsLoader(false);
  };

  const deleteRequest = (item, bypass) => {
    if (item && item?.Useremail === acct?.email && !bypass) {
      setIsDeleteConfirmModalOpen(true);
      setUserToBeDelete(item);
      return;
    }

    const { Useremail } = item;

    if (!Useremail) {
      return message.error("Something Went Wrong");
    }
    const body = {
      emailId: Useremail,
      user_role: item?.role,
    };
    IntegrationSDK.deleteUser(body).then(() => {
      handleFetchAccountDetails();
      message.success("User deleted successfully");
    });
  };

  /** Setting User Data */
  useEffect(() => {
    console.log("Setting User Data");
    setUserRequestData();
    setUsersData();
    // eslint-disable-next-line
  }, [acct, acct?.users?.length]);

  console.log("ACCT ", Users);

  /** For Fetching account */
  useEffect(() => {
    handleFetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchTextReq?.length === 0) setUserRequestData();
    else {
      let requestFiltered = [];
      requestFiltered = userRequests.filter((item) =>
        String(item.owner).includes(searchTextReq)
      );
      setuserRequests(requestFiltered);
    }
    // eslint-disable-next-line
  }, [searchTextReq]);

  useEffect(() => {
    if (searchText?.length === 0) setUsersData();
    else {
      let userFilterd = Users?.filter(
        (item) =>
          String(item?.UserFullName).toLowerCase().includes(searchText) ||
          String(item?.Useremail).toLowerCase().includes(searchText)
      );
      setUsers(userFilterd);
    }
    // eslint-disable-next-line
  }, [searchText]);

  const getUserRole = (roleName) => {
    const getUserRenderColor = (userType) => {
      switch (userType) {
        case "normal_user":
          return "green";
        case "SchoolAccountOwner":
          return "orange";
        case "SchoolAdmin":
          return "purple";
        default:
          return "volcano";
      }
    };

    return (
      <Tag color={getUserRenderColor(roleName)}>{startCase(roleName)}</Tag>
    );
  };

  const swapUserRole = async () => {
    setIsLoader(true);
    console.log({ swapRoleDetails });
    const { emailId, old_user_role } = swapRoleDetails;
    const body = {
      emailId,
      new_user_role: changeUserRoleForm?.getFieldValue("new_user_role"),
      old_user_role,
    };

    console.log({ body });
    try {
      await Accounts.updateUserRole(body);
      await handleFetchAccountDetails();
      setApiResultModal(() => ({
        message: "Role assigned successfully",
        isApiResultModelOpen: true,
        type: "success",
      }));
    } catch (e) {
      setApiResultModal(() => ({
        message: "Something went wrong",
        isApiResultModelOpen: true,
        type: "failure",
      }));
    } finally {
      setIsLoader(false);
      setChangeUserRoleModalOpen(false);
    }
  };

  const handleMFA = async (item) => {
    const { MfaEnable, Username } = item;

    const body = {
      emailId: Username,
      enable: !MfaEnable,
    };
    setIsLoader(true);
    const data = await sdk.updateMFA(body);
    setTimeout(async () => {
      console.log("Hello world");
      setIsLoader(true);
      await handleFetchAccountDetails();
      setIsLoader(false);
    }, 1000);
  };

  const handleChangeUserRole = (item) => {
    setChangeUserRoleModalOpen(true);
    setSwapRoleDetails(() => ({
      emailId: item?.Useremail,
      old_user_role: item?.role,
    }));
  };

  console.log("==> :: ", swapRoleDetails);

  return (
    <>
      {acct && !isLoading ? (
        <>
          <Row className="pt-10 " gutter={[24, 24]}>
            {isNormalUser ? null : (
              <Col span={24}>
                <div className="invite-container">
                  <div className="invite-image-container">
                    <Image preview={false} src={InviteUserDetails} alt="..." />
                  </div>

                  <div className="invite-text-container flex-col align-center flex-between">
                    <span className="invite-team-member">
                      {t(InviteTeamMemberText_P1)} <br />
                      {t(InviteTeamMemberText_P2)}
                    </span>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => setIsInviteModelOpen(true)}
                    >
                      {t(Invite_Team_Title)}
                    </Button>
                  </div>
                </div>
              </Col>
            )}

            <Col span={24}>
              <Tabs className="userTab">
                <TabPane tab={t(users)} key="1">
                  <Row className="user-search-container d-flex flex-wrap">
                    <Col span={22}>
                      <Input
                        value={searchText}
                        onChange={(event) => setsearchText(event.target.value)}
                        className="searchbar"
                        prefix={
                          <SearchOutlined className="searchbar-search-icon" />
                        }
                        allowClear
                        placeholder={t(Search_Team_Member_Text)}
                      />
                    </Col>
                    <Col
                      span={2}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <RedoOutlined
                        rotate={210}
                        onClick={() => handleFetchAccountDetails()}
                        className="fs-24 refresh-icon"
                      />
                    </Col>
                  </Row>
                  <List
                    itemLayout="horizontal"
                    dataSource={Users}
                    renderItem={(item, index) => (
                      <List.Item
                        className="user-details-action-wrapper"
                        actions={
                          isNormalUser
                            ? null
                            : [
                                <Dropdown
                                  placement="bottomRight"
                                  overlay={
                                    <Menu>
                                      <Menu.ItemGroup>
                                        {item?.role !== "SchoolAccountOwner" ||
                                        item?.role !== "admin" ? (
                                          <>
                                            <Menu.Item key="1">
                                              <span
                                                className="color-black"
                                                onClick={() =>
                                                  handleChangeUserRole(item)
                                                }
                                              >
                                                Assign New User Role
                                              </span>
                                            </Menu.Item>
                                          </>
                                        ) : null}
                                        {!isNormalUser ? (
                                          <>
                                            <Menu.Item
                                              key="99"
                                              disabled={
                                                !item?.MfaEnable &&
                                                get_attribute(
                                                  item?.Attributes,
                                                  "custom:mfa"
                                                ) === "true"
                                              }
                                            >
                                              <span
                                                onClick={() => handleMFA(item)}
                                              >
                                                {item?.MfaEnable
                                                  ? "Disable MFA"
                                                  : "Enable MFA"}
                                              </span>
                                            </Menu.Item>
                                          </>
                                        ) : null}
                                        <Menu.Item
                                          key="2"
                                          disabled={
                                            Users?.length === 1 &&
                                            searchText?.length === 0
                                          }
                                        >
                                          <Popconfirm
                                            className="switchDialog"
                                            style={{
                                              zIndex: 9999,
                                            }}
                                            placement="bottomRight"
                                            title={
                                              <Typography.Title
                                                level={5}
                                                className="m-0"
                                              >
                                                {Users.length === 1 &&
                                                searchText?.length === 0
                                                  ? "This is the last user in the account and cannot be deleted."
                                                  : t(deleteUserText)}
                                              </Typography.Title>
                                            }
                                            onConfirm={
                                              Users.length === 1 &&
                                              searchText?.length === 0
                                                ? null
                                                : () => deleteRequest(item)
                                            }
                                            okText={
                                              Users.length === 1 &&
                                              searchText?.length === 0 ? (
                                                "Ok"
                                              ) : (
                                                <span className="admin-btn">
                                                  {t(yesText)}
                                                </span>
                                              )
                                            }
                                            cancelText={"No"}
                                            icon={""}
                                            okType="primary"
                                            okButtonProps={{
                                              size: "large",
                                            }}
                                            cancelButtonProps={{
                                              size: "large",
                                              className: "home-no-btn",
                                            }}
                                            showCancel={Users.length !== 1}
                                          >
                                            <span className="d-flex gap-1">
                                              {t(Delete_User_Text)}
                                              {Users.length === 1 &&
                                              searchText?.length === 0 ? (
                                                <InfoCircleOutlined className="color-black" />
                                              ) : null}
                                            </span>
                                          </Popconfirm>
                                        </Menu.Item>
                                      </Menu.ItemGroup>
                                    </Menu>
                                  }
                                >
                                  <MoreOutlined className="fs-20" />
                                </Dropdown>,
                              ]
                        }
                      >
                        <List.Item.Meta
                          title={
                            <Typography.Title
                              level={5}
                              className="font-weight-500"
                            >
                              {item.Useremail} {getUserRole(item?.role)}
                              <Tag color={item?.MfaEnable ? "green" : null}>
                                MFA &nbsp;
                                {item?.MfaEnable ? "Enabled" : "Disabled"}
                              </Tag>
                              {!item?.MfaEnable &&
                              get_attribute(item?.Attributes, "custom:mfa") ===
                                "true" ? (
                                <Tag color="red">Required MFA</Tag>
                              ) : null}
                            </Typography.Title>
                          }
                          description={
                            item.UserFullName?.length > 1
                              ? item.UserFullName
                              : "-"
                          }
                        />
                      </List.Item>
                    )}
                  />
                </TabPane>
                {isNormalUser ? null : (
                  <TabPane tab={t(Requests_Text)} key="2">
                    <List
                      className="mtop-10 requestTab"
                      itemLayout="horizontal"
                      dataSource={userRequests}
                      renderItem={(item) => (
                        <List.Item
                          className="overflow-wrap user-details-list-item"
                          actions={[
                            <Tooltip title={`Approve ${item.owner}`}>
                              <Button
                                key="list-loadmore-edit"
                                className="user-details-list-item-button user-details-list-item-button-primary"
                                onClick={() =>
                                  approveOrDenyUserRequest(item, true)
                                }
                              >
                                {t(Approve_Text)}
                              </Button>
                            </Tooltip>,
                            <Tooltip title={`Deny ${item.owner}`}>
                              <Button
                                key="list-loadmore-more"
                                className="user-details-list-item-button user-details-list-item-button-outer-space"
                                onClick={() =>
                                  approveOrDenyUserRequest(item, false)
                                }
                              >
                                {t(Deny_Text)}
                              </Button>
                            </Tooltip>,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <a
                                className="fs-18"
                                href={`mailto:${item.owner}`}
                              >
                                {item.owner}
                              </a>
                            }
                            description={
                              <Moment fromNow>{item.createdAt}</Moment>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Col>
          </Row>
        </>
      ) : (
        <>{getSkeletonBlock()}</>
      )}
      <InviteModal
        isInviteModelOpen={isInviteModelOpen}
        setIsInviteModelOpen={setIsInviteModelOpen}
        setAcct={setAcct}
      />
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
      <CustomModal
        visible={isDeleteConfirmModalOpen}
        closable={true}
        centered
        width={"50%"}
        footer={null}
        // className="suspend-company-footer br_20"
        onCancel={() => setIsDeleteConfirmModalOpen(false)}
      >
        <Row align="middle" className="flex-col mx-4" gutter={[24, 24]}>
          <Image src={AssignNewImg} preview={false} height={200} />
          <div>
            <Title level={5} className="m-0 text-justify ">
              This is the creator of the SchoolBI profile and if you delete this
              user you will have to assign the rights to some other user. Are
              you sure you want to proceed?
            </Title>
          </div>
          <Form
            form={selectAUserForm}
            className="multi-worksheet-form"
            layout="vertical"
          >
            <Form.Item label="Select the user:" name={"userEmail"}>
              <Select
                options={selectAuser}
                className="login-module-input"
                style={{
                  width: "450px",
                }}
              />
            </Form.Item>
          </Form>
          <Row className="w-100" justify="end">
            <Button
              className="secondary-btn"
              onClick={() => setIsDeleteConfirmModalOpen(false)}
            >
              Don't Delete
            </Button>
            <Button
              loading={loader}
              onClick={() => updateSchoolProfile()}
              type="primary"
            >
              Proceed
            </Button>
          </Row>
        </Row>
      </CustomModal>

      <CustomModal
        visible={changeUserRoleModalOpen}
        closable={true}
        centered
        width={"30%"}
        footer={null}
        onCancel={() => setChangeUserRoleModalOpen(false)}
        maskClosable={false}
      >
        <Row align="middle" className="flex-col mx-4" gutter={[12, 12]}>
          <Image src={AssignNewImg} preview={false} height={200} />
          <div>
            <Title level={5} className="m-0 text-justify ">
              Assign User Role
            </Title>
          </div>
          <Form
            form={changeUserRoleForm}
            className="multi-worksheet-form"
            layout="vertical"
          >
            <Form.Item label="" name={"new_user_role"}>
              <Select
                options={userRole?.filter(
                  ({ value }) => value !== swapRoleDetails?.old_user_role
                )}
                className="login-module-input mb-4"
                style={{
                  width: "300px",
                }}
                placeholder="Select a role"
              />
            </Form.Item>
          </Form>
          <Row className="w-100" justify="end">
            <Button
              className="secondary-btn"
              onClick={() => setChangeUserRoleModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              loading={loader}
              onClick={() => swapUserRole()}
              type="primary"
            >
              Assign
            </Button>
          </Row>
        </Row>
      </CustomModal>
    </>
  );
}
