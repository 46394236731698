import React from "react";
import { Col, Row } from "antd";
import CustomDashboard from "./CustomDashboard";
import "./quicksight.less";

const QsDashboard = () => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <CustomDashboard />
        </Col>
      </Row>
    </>
  );
};

export default QsDashboard;
