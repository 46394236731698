//** React Imports */
import React, { useState, useEffect } from "react";
//** Ant Design Imports */
import { Col, Row, Form, Input, Button, Alert, Divider, message } from "antd";
//** Third Party Imports */
import { useTranslation } from "react-i18next";
//** Constant Imports */
import {
  buttonText,
  emailPattern,
  formError,
  formLabel,
  MiscellaneousText,
} from "utility/constants";
//** Amplify Imports */
import { Auth } from "aws-amplify";
//** Redux Imports */
import { connect, useDispatch } from "react-redux";
//** Redux Action Imports */
import { login, setIsLoading, setLoginData } from "store/actions/loginAction";
//** Layout Imports */
import Footer from "Common/Layouts/Footer.js";
import Background from "Common/Layouts/AuthColOne.js";
import LoginModuleLogo from "Common/Components/LoginModuleLogo/LoginModuleLogo";
import { Typography } from "antd";
import { QRCode } from "react-qrcode-logo";
import OtpInput from "react-otp-input";

/**
 * @function base component
 * @param {*} props
 */
const Login = (props) => {
  /** Destructing Hooks */
  const { t } = useTranslation();
  //** Destructing Constants */
  const { isEmailInvalid, isEmailEmpty, isPasswordEmpty } = formError;
  const { Email_Label, emailText, Password_Label } = formLabel;
  const { Forget_Password_Text, Login_Text, Sign_Up_Text } = buttonText;
  const { Do_not_Account_Text } = MiscellaneousText;

  const [form] = Form.useForm();

  //** Initial Form Data of Login Form */
  const initialValues = {
    userName: "",
    password: "",
    loading: false,
    feedback: props.history.location.state
      ? props.history.location.state.feedback
      : "",
    error: "",
  };

  const [state, setState] = useState(initialValues);
  const [totpUri, setTotpUri] = useState();
  const [secretCode, setSecretCode] = useState();
  const [otp, setOtp] = useState();
  const [user, setUser] = useState(null);
  const [isMFA, setIsMFA] = useState(false);

  const { email, loading, error, feedback } = state;

  const dispatch = useDispatch();

  /**
   * Handles sign in of user
   */
  const handleSubmit = async (e) => {
    setState((pS) => ({ ...pS, loading: true, error: "" }));

    //Invalid MFA Code
    //Session expires
    if (!(secretCode && totpUri) && isMFA) {
      if (!user || !otp) {
        return;
      }
      return await Auth.confirmSignIn(user, otp, "SOFTWARE_TOKEN_MFA")
        .then((data) => {
          const { signInUserSession, attributes } = data;
          dispatch(setLoginData(signInUserSession, attributes));
          props.history.push("/");
          setState((pS) => ({
            ...pS,
            error: "",
            loading: false,
            userName: "",
            password: "",
          }));
        })
        .catch((error) => {
          setState((pS) => ({
            ...pS,
            error: error.message,
            loading: false,
          }));
          setOtp(null);
          form.setFieldsValue({ totpToken: "" });
        });
    }

    if (secretCode && totpUri) {
      if (!otp || !user) {
        return;
      }
      try {
        await Auth.verifyTotpToken(user, String(otp));
        await Auth.setPreferredMFA(user, "TOTP");
        await Auth.updateUserAttributes(user, {
          "custom:mfa": "false",
        });
        const { signInUserSession, attributes } = user;
        dispatch(setLoginData(signInUserSession, attributes));
        props.history.push("/");
      } catch (e) {
        console.log("Error : ", e);
        if (e?.code === "NotAuthorizedException") {
          localStorage.clear();
          // props.history.push("/signin");
          setIsMFA(false);
          setOtp(null);
          setUser(null);
          setTotpUri(null);
          setSecretCode(null);
          form.resetFields();
          return message.error("Please signin again");
        }
        message.error(e?.message);
      } finally {
        setState((pS) => ({
          ...pS,
          loading: false,
        }));
      }
    } else {
      await props
        .loginData(e.userName, e.password)
        .then(async (response) => {
          console.log({ response });
          setUser(response);
          // await Auth.currentAuthenticatedUser().catch((err) => console.log(err));
          if (
            response?.challengeName === "NEW_PASSWORD_REQUIRED" ||
            response?.challengeName === "NEW_PASSWORD_REQUIRED"
          ) {
            props.history.push({
              pathname: `/create-password`,
              state: {
                userAttributes: response?.challengeParam?.userAttributes,
                oldPassword: e.password,
              },
            });
            // history.push("/");
          }

          if (
            response?.challengeName === "MFA_SETUP" ||
            (response?.preferredMFA === "NOMFA" &&
              response?.attributes?.["custom:mfa"] === "true")
          ) {
            setState((pS) => ({ ...pS, loading: false }));
            console.log("INSIDE setup MFA");
            const secretCode = await Auth.setupTOTP(response);
            const totpSetupUri = `otpauth://totp/${encodeURIComponent(
              "SchoolBI"
            )}:${encodeURIComponent(
              e.userName
            )}?secret=${secretCode}&issuer=${encodeURIComponent("SchoolBI")}`;
            setSecretCode(secretCode);
            setTotpUri(totpSetupUri);
            setIsMFA(true);
            return;
          }
          console.log("Hello MFA");
          if (
            response?.challengeName === "SOFTWARE_TOKEN_MFA" ||
            response?.preferredMFA === "SOFTWARE_TOKEN_MFA"
          ) {
            console.log("INSIDE challenge");
            setIsMFA(true);
          }
          setState((pS) => ({ ...pS, loading: false }));
        })
        .catch((error) => {
          if (error?.message === "User is not confirmed.") {
            props?.history.push({
              pathname: `/confirm_email`,
              state: { feedback: "", userName: e.userName },
            });
          }
          setState((pS) => ({
            ...pS,
            error: error.message,
            loading: false,
            userName: "",
            password: "",
          }));
        });
    }
  };

  useEffect(() => {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
    } else {
      sessionStorage.removeItem("reloadCount");
    }
  }, []);

  return (
    <>
      <Row justify="center">
        <Background />

        <Col span={12} md={12} lg={12} xs={24}>
          <Row
            gutter={24}
            justify="center"
            align="middle"
            className="login-module-col-2"
          >
            <Col span={12} md={24} lg={18} xl={12} xs={24} sm={24} xxl={12}>
              <LoginModuleLogo />
              {feedback && (
                <Alert
                  className="mb-10"
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  className="mb-10"
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                layout="vertical"
                name="normal_login"
                // className="login-form"
                requiredMark={false}
                onFinish={handleSubmit}
                form={form}
              >
                {totpUri && secretCode ? (
                  <Row align="middle" justify="center">
                    <Typography.Title level={5} className="m-0">
                      QR code for Authenticator
                    </Typography.Title>
                    <QRCode size={180} value={totpUri} />
                    {/*  <Divider>OR</Divider>

                    <Typography.Paragraph copyable>
                      {secretCode}
                    </Typography.Paragraph>
                   <Typography.Title level={5}>
                      Copy This Setup Key and Paste It in Your TOTP
                      Authenticator Application to Generate Your Token.
                    </Typography.Title> */}
                  </Row>
                ) : null}

                <Form.Item
                  label={t(Email_Label)}
                  initialValue={email}
                  name="userName"
                  className="font-bold"
                  rules={[
                    { required: true, message: t(isEmailEmpty) },
                    {
                      pattern: emailPattern,
                      message: t(isEmailInvalid),
                    },
                  ]}
                >
                  <Input
                    id="userName"
                    key="userName"
                    name="userName"
                    placeholder={emailText}
                  />
                </Form.Item>

                {isMFA ? (
                  <Form.Item
                    className="otp"
                    label="Verification Code"
                    name="totpToken"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your verification code.",
                      },
                    ]}
                  >
                    <OtpInput
                      name="code"
                      id="code"
                      key="code"
                      shouldAutoFocus
                      className="otp-input"
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      numInputs={6}
                      isInputNum={true}
                      //Dev Comment :- isInputSecure={true}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Password"
                    name="password"
                    className="font-bold"
                    rules={[
                      {
                        required: true,
                        message: t(isPasswordEmpty),
                      },
                    ]}
                  >
                    <Input.Password
                      id="password"
                      key="password"
                      name="password"
                      placeholder={t(Password_Label)}
                    />
                  </Form.Item>
                )}

                {!(secretCode || totpUri || isMFA) ? (
                  <Row align="end">
                    <Button
                      onClick={() => props.history.push("/forgotpassword")}
                      type="link"
                      className="color-black p-0"
                    >
                      {t(Forget_Password_Text)}
                    </Button>
                  </Row>
                ) : null}

                <Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    block
                    type="primary"
                    tabIndex={5}
                    loading={loading}
                  >
                    {t(Login_Text)}
                  </Button>
                  {/* Dev Comment <Divider style={{color: '#727272'}}>or</Divider> 
                  <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      style={{
                        background: "#FFFFFF",
                        color: "#303030",
                        borderRadius: "8px",
                        height: "44px",
                      }}
                    >
                      <img src={GoogleIcon} style={{ padding: 7 }} />
                      <span>Sign in with google</span>
                    </Button> */}
                </Form.Item>
                <Row justify="center">
                  <Typography.Text>{t(Do_not_Account_Text)}</Typography.Text>
                  &nbsp;
                  <Typography.Link
                    className="font-bold"
                    onClick={() => props.history.push("/signup")}
                  >
                    {t(Sign_Up_Text).toUpperCase()}
                  </Typography.Link>
                </Row>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    prop: state.prop,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginData: (userName, password) => dispatch(login(userName, password)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
