import { API } from "aws-amplify";
import { listQsdashboardDetailsCustom } from "graphql/customQueries";
import {
  createFavorite,
  deleteFavorite,
  updateQsdashboardDetail,
} from "graphql/mutations";
import { GraphqlOperationExecutor } from "utility/commonMethods";

const getAllCustomDashboard = (filter, userId) => {
  const queryData = {
    query: listQsdashboardDetailsCustom,
    body: {
      filter,
      userId,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const getAIInsight = async (body) => {
  const data = await API.post("schoolBIREST", "/qs/qna", { body });
  return data;
};

const updateDashboardDetails = (input) => {
  const queryData = {
    query: updateQsdashboardDetail,
    body: {
      input,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const createFavoriteDashboard = (input) => {
  const queryData = {
    query: createFavorite,
    body: {
      input,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const deleteFavoriteDashboard = (input) => {
  const queryData = {
    query: deleteFavorite,
    body: {
      input,
    },
  };

  return GraphqlOperationExecutor(queryData);
};

const QsDashboardSDK = {
  getAllCustomDashboard,
  getAIInsight,
  updateDashboardDetails,
  createFavoriteDashboard,
  deleteFavoriteDashboard,
};

export default QsDashboardSDK;
