//** Amplify Imports */
import { API, graphqlOperation, Storage } from "aws-amplify";
//** Redux action imports */
import { setIsLoading } from "store/actions/loginAction";
//** Redux Imports */
import { store } from "store/index";
//** Ant Component Imports */
import { message } from "antd";
//** Imports Constants */
import { emailPattern } from "./constants";
// ** SDK imports */
import sdk from "sdk/Accounts";
import { multiply } from "lodash";

export const swapKeyValue = (json) => {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

/**
 * Getting User Attribute From User by Key
 * @function get_attribute
 * @param {Array,String}
 * @return {String}
 */
export const get_attribute = (user, key) => {
  let result = user.filter((attribute) => attribute.Name === key);
  if (result.length > 0) {
    return result[0]["Value"];
  } else {
    return "";
  }
};

/**
 * Executes Graphql query
 * @param {Object} Param Accepts json object of query parameters
 * @returns
 */
export const GraphqlOperationExecutor = async (masterParam) => {
  try {
    store.dispatch(setIsLoading(true));

    let masterResult;

    let hasNextToken = true;
    let nextToken = null;

    while (hasNextToken) {
      const resultPromise = API.graphql(
        graphqlOperation(...Object.values(masterParam))
      );
      const result = await resultPromise;

      const key = Object.keys(result?.data)[0];
      const data = result?.data?.[key];

      if (!data?.items) {
        masterResult = data;
        hasNextToken = false;
        store.dispatch(setIsLoading(false));
        return masterResult || {};
      }

      const { nextToken: currentNextToken, items } = data;

      if (!masterResult) {
        masterResult = [];
      }
      if (items.length > 0) {
        masterResult.push(...items);
      }

      if (!currentNextToken) {
        hasNextToken = false;
        store.dispatch(setIsLoading(false));
        return masterResult || [];
      }

      nextToken = currentNextToken;
      masterParam.body.nextToken = nextToken;
      hasNextToken = Boolean(nextToken);
    }

    store.dispatch(setIsLoading(false));
    return masterResult || [];
  } catch (e) {
    store.dispatch(setIsLoading(false));
    console.log(e);
    return;
  }
};

/**
 * Executes function
 * @function apiExecuter
 * @param {Function} func Accepts Functions
 * @returns
 */
export const apiExecuter = async (func) => {
  try {
    store.dispatch(setIsLoading(true));
    const result = await func();
    store.dispatch(setIsLoading(false));
    return result;
  } catch (e) {
    store.dispatch(setIsLoading(false));
    console.log("Failed in executing", func, "\nError occurs ", e);
    const { data } = e?.response;
    throw data || "Something went wrong";
  }
};

/**
 * Validating Email On Input given while inviting
 * @function validateEmail
 * @param {String} email
 * @returns
 */
export const validateEmail = (e) => {
  const email_pattern = emailPattern;
  if (e?.length > 0) {
    const found = e[e.length - 1].match(email_pattern);
    if (!found) {
      e.pop();
      return message.error("Please enter valid email");
    } else {
      return (e[e.length - 1] = e[e.length - 1].toLowerCase());
    }
  }
};

/**
 * Sorting data by order
 * @function sortingOrder
 * @param {Object,Object} {a,b}
 * @returns
 */
export const sortingOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

/**
 *  Sending invitation to all email list
 * @function sendInvitation
 * @param {Object} value it contains account details
 */
export const sendInvitation = async (inviteData, value) => {
  return await apiExecuter(async () => {
    value.inviteBy = inviteData?.inviteBy;
    return await sdk.sendEmailInvitation(value);
  });
};

/**
 * Fetches current school data and current account
 * @function setCompanyData
 * @param {Object} account
 */
export const setCompanyData = (account, setAcct) => {
  if (!account?.url?.includes("http")) {
    account.url = "https://" + account?.url;
  }
  setAcct(account);
};

/**
 * Fetching account for updating
 * @function fetchAccountDetails
 */
export const fetchAccountDetails = async (setAcct) =>
  await apiExecuter(async () => {
    const groups = store.getState()?.loginReducer?.groups;
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      if (setAcct) setCompanyData(account, setAcct);
    }
  });

export const getFieldKey = (key) =>
  key?.toString()?.trim()?.toLowerCase()?.replace(/\W/g, "_");

export const AskNotificationPermission = () => {
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
  } else if (checkNotificationPromise()) {
    Notification.requestPermission().then((permission) => {});
  } else {
    Notification.requestPermission((permission) => {});
  }

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  }
};

/**
 *
 * @param {*} currentTime
 * @param {*} PreviousTime
 * @returns Differnce
 */
export const gettimeDiff = (currentTime, PreviousTime) => {
  var ms_Min = 60 * 1000;
  var ms_Hour = ms_Min * 60;
  var ms_Day = ms_Hour * 24;
  var ms_Mon = ms_Day * 30;
  var ms_Yr = ms_Day * 365;
  var diff = currentTime - PreviousTime;
  if (diff < ms_Min) {
    return Math.round(diff / 1000) + " seconds";
  } else if (diff < ms_Hour) {
    return Math.round(diff / ms_Min) + " minutes";
  } else if (diff < ms_Day) {
    return Math.round(diff / ms_Hour) + " hours";
  } else if (diff < ms_Mon) {
    return "Around " + Math.round(diff / ms_Day) + " days";
  } else if (diff < ms_Yr) {
    return "Around " + Math.round(diff / ms_Mon) + " months";
  } else {
    return "Around " + Math.round(diff / ms_Yr) + " years";
  }
};

export const sortDatabyDate = (data) =>
  data.sort((a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);
    return dateA - dateB;
  });

/**
 *
 * @param {*} fileName
 * @returns
 */
export const replaceFileName = (name) => {
  const pattern = /[^a-zA-Z0-9.-]/g;
  let fileName = `${name?.replace(/\.[^/.]+$/, "")}.${name
    ?.replace(/(.*)\./g, "")
    ?.replace(/ /g, "")}`;

  fileName = fileName.replace(pattern, "");

  return fileName;
};

export const SaveFileToS3 = async (file, level = "public", prefix = "") =>
  await Storage.put(`${prefix}/${replaceFileName(file?.name)}`, file, {
    level,
    contentDisposition: "attachment",
  });

const extractPathFromUrl = (url) => {
  const parts = url.split("private/");

  if (parts.length > 1) {
    let path = parts[1].split("?")[0];
    path = path.replace(/^us-east-1%3A/, "");
    return path;
  }

  return null;
};

export const SaveFileToS3ForCSV = async (
  file,
  level = "public",
  prefix = ""
) => {
  const data = await Storage.put(
    `${prefix}/${replaceFileName(file?.name)}`,
    file,
    {
      level,
      contentDisposition: "attachment",
    }
  );

  const { key } = data;
  const getUrl = await Storage.get(key, {
    level: "private",
  });

  const extractedKey = extractPathFromUrl(getUrl);
  return { key: extractedKey };
};

export const getEnv = () => {
  const host = window?.location?.host || "";

  if (host.includes("stage")) return "stagging";
  if (host.includes("app")) return "production";
  return "stagging";
};

export const TimezoneDifference = () => {
  const now = new Date();
  const timezoneOffsetMinutes = now.getTimezoneOffset();
  const timezoneOffsetInMinutes = multiply(-timezoneOffsetMinutes, 1);
  return timezoneOffsetInMinutes;
};
