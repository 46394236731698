//** React imports */
import React, { useState, useEffect, useMemo } from "react";
//** React Router imports */
import { Link, useLocation } from "react-router-dom";
//** Ant imports */
import { Menu, Layout, Button } from "antd";
//** Logo imports */
import BeeIcon from "assets/images/bee.svg";
import logo from "assets/images/WhiteLogo.svg";
import BeeWhiteLogo from "assets/images/Logo/BeeWhiteLogo.svg";
//** Sider imports */
import SiderMenu, { FooterMenu, ReportBuilderIcon } from "utility/context/Menu";
import SubMenu from "antd/lib/menu/SubMenu";
//** Third Party imports */
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { getAccountCustom } from "graphql/customQueries";
import { setAccountDetails } from "store/actions/loginAction";
import {
  setManageFilesVisibility,
  setAdvancedListVisibility,
} from "store/actions/settingAction";
import classNames from "classnames";
import useCustomBreakpoints from "utility/UseCustomBreakpoints";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import LogoutModal from "Common/Components/LogoutModal/LogoutModal";
import { getEnv } from "utility/commonMethods";

/**
 * Side menu component
 * @function Sider
 * @return {<Sider Content/>}
 */
const Sider = (props) => {
  //** Destructing Any Components */
  const { Sider } = Layout;

  //** Destructing Props */
  const { tieringAccess, collapsed, toggleCollapsed } = props;

  //** Destructing Hooks */
  const location = useLocation();
  const dispatch = useDispatch();
  const { isManageFilesVisibility, isAdvancedListsVisibility } = useSelector(
    (state) => state.settingReducer
  );
  const { groups } = useSelector((state) => state.loginReducer);

  const screens = useCustomBreakpoints();

  const initaildefaultKey = {
    defaultOpenKeys: ["datamanagement", "configure"],
  };

  //** State for handling Key for Side Menu */
  const [selectedKey, setSelectedKey] = useState("");
  const [defaultKey, setDefaultKey] = useState(initaildefaultKey);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        setSelectedKey("home");
        break;
      default:
        const key = window.location.pathname.replace("/", "");
        setSelectedKey(key);
        break;
    }
  }, [location]);

  const fetchAccountDetails = async () => {
    const accountId = groups?.length > 0 ? groups[0] : null;
    if (accountId) {
      const {
        data: { getAccount },
      } = await API.graphql(
        graphqlOperation(getAccountCustom, { id: accountId })
      );
      const { datasource } = getAccount;

      if (datasource?.some((item) => item?.toLowerCase() === "custom_files")) {
        dispatch(setManageFilesVisibility(false));
      }
      if (!datasource?.some((item) => item?.toLowerCase() === "custom_files")) {
        dispatch(setManageFilesVisibility(true));
      }
      if (
        datasource?.some(
          (item) => item?.toLowerCase() === "blackbaud_advanced_list_management"
        )
      ) {
        dispatch(setAdvancedListVisibility(false));
      }
      if (
        !datasource?.some(
          (item) => item?.toLowerCase() === "blackbaud_advanced_list_management"
        )
      ) {
        dispatch(setAdvancedListVisibility(true));
      }

      dispatch(setAccountDetails(getAccount));
    }
  };

  const LookerStudioConnecterId = useMemo(
    () => ({
      stagging:
        "AKfycbxz3KKPZxHraRwlEKNdTBO1F0zwdZq8ohhMPO1_nWR3q8R4Er7tV784AuxnQLizbsYkmQ",
      production:
        "AKfycbzzkKjlQkL7Ctz3yewjkRf1QLAgCmITmuMkrzSQQMDdJP2A3prUrUwATAc-suKkjoZGOg",
    }),
    []
  );

  useEffect(() => {
    const { xs, sm, md, lg } = screens;
    if (xs || sm || md || lg) {
      setDefaultKey({ defaultOpenKeys: [] });
    } else if (!collapsed) {
      setDefaultKey(initaildefaultKey);
    }
    //eslint-disable-next-line
  }, [...Object.values(screens), collapsed]);

  useEffect(() => {
    fetchAccountDetails();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Sider Container Start  */}
      <Sider
        collapsedWidth="80"
        trigger={null}
        width={300}
        collapsible
        collapsed={collapsed}
        className="sider"
      >
        <div className="explores-sider-menu">
          <div className="d-flex flex-column">
            {/* SchoolBI Logo  */}

            {!collapsed ? (
              <div className="logo-container">
                <img src={logo} className="logo m-0" alt="" />
              </div>
            ) : (
              <div className="collapsed-logo-container">
                <img src={BeeWhiteLogo} className="logo m-0" alt="" />
              </div>
            )}

            <Button
              type="primary"
              onClick={toggleCollapsed}
              className="collapse-navbar"
              size="small"
            >
              {!collapsed ? <LeftOutlined /> : <RightOutlined />}
            </Button>

            <div data-tour="tour__modules" className="sider-menu-container">
              {/* Menu Container Start */}
              <Menu
                theme="dark"
                mode="inline"
                selectedKeys={selectedKey}
                className="bg-none mt-18 main-menu"
                inlineIndent={10}
                inlineCollapsed={collapsed}
                defaultOpenKeys={collapsed ? null : defaultKey?.defaultOpenKeys}
              >
                {/* Mapping slider menu for rendering
                 * @param {Object of menu from constant}
                 * @return ={<Slider Menu Content/>}
                 */}

                {SiderMenu.map((item) => {
                  const { key, title, icon, path, isSubMenu } = item;

                  return isSubMenu
                    ? tieringAccess?.[key]?.enable && (
                        <SubMenu
                          key={key}
                          className={classNames("subMenuItem", {
                            "datamanagement-item": key === "datamanagement",
                          })}
                          icon={icon}
                          title={title}
                          popupOffset={24}
                        >
                          {item.subMenu.map((subItem) => {
                            const { key, title, icon, isSubMenu } = subItem;

                            return isSubMenu ? (
                              <SubMenu
                                key={key}
                                className={classNames("subMenuItem", {
                                  "configure-submenuitem":
                                    key === "configure" ||
                                    key === "dataprovider",
                                })}
                                icon={icon}
                                title={title}
                                // id="myslider-menuid"
                              >
                                {subItem.subMenu.map((subItem) => {
                                  const { key, title, icon, path } = subItem;

                                  return (
                                    <Menu.Item
                                      key={key}
                                      icon={icon}
                                      id={key}
                                      disabled={
                                        key === "managefiles"
                                          ? isManageFilesVisibility
                                          : key === "advancedlist"
                                          ? isAdvancedListsVisibility
                                          : false
                                      }
                                      className={classNames(
                                        "sider-submenu-container"
                                      )}
                                    >
                                      <Link
                                        to={path}
                                        className="link-container"
                                      >
                                        {title}
                                      </Link>
                                    </Menu.Item>
                                  );
                                })}
                              </SubMenu>
                            ) : (
                              <Menu.Item
                                key={subItem.key}
                                icon={subItem.icon}
                                id={key}
                                className={classNames(
                                  "sider-submenu-container",
                                  {
                                    "single-dataprovider":
                                      key === "dataprovider",
                                    "px-2": collapsed,
                                  }
                                )}
                              >
                                <Link
                                  to={subItem.path}
                                  className="link-container"
                                >
                                  {subItem.title}
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      )
                    : tieringAccess?.[key] && (
                        <Menu.Item
                          key={key}
                          icon={icon}
                          id={key}
                          className={"bg-none"}
                        >
                          <Link to={path}>{title}</Link>
                        </Menu.Item>
                      );
                })}
              </Menu>
              {/* Menu Container End */}
            </div>
          </div>
          {/* Left Below honeycomb image */}
          <img
            src={BeeIcon}
            className="footer-logo position-absolute w-100 bottom-0"
            alt="Honeycomb"
          />

          <div className={classNames({ "footer-menu": !collapsed })}>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={selectedKey}
              className="bg-none mt-18 main-menu"
              inlineIndent={10}
              inlineCollapsed={collapsed}
              getPopupContainer={(triggerNode) => {
                return triggerNode?.parentNode;
              }}
            >
              {FooterMenu.map((item) => {
                const { key, title, icon, path, onClick } = item;
                return (
                  <Menu.Item
                    key={key}
                    icon={icon}
                    id={key}
                    className={classNames({
                      "sider-submenu-container ": !collapsed,
                      "padding-675": collapsed,
                    })}
                    onClick={
                      key === "logout"
                        ? () => {
                            setLogoutModalOpen(true);
                          }
                        : null
                    }
                  >
                    {key === "logout" ? (
                      <span className="link-container">{title}</span>
                    ) : (
                      <Link to={path} className="link-container">
                        {title}
                      </Link>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu>

            <Menu
              theme="light"
              mode="vertical"
              className="vertical-sider-menu report-builder-menu"
              // openKeys={"menu"}
              // forceSubMenuRender={true}
              getPopupContainer={(triggerNode) => {
                return triggerNode?.parentNode;
              }}
            >
              <SubMenu
                key={"menu"}
                icon={<ReportBuilderIcon />}
                title={
                  <span className="color-white font-bold">Report Builder</span>
                }
                className={classNames("vertical-subMenuItem ")}
                popupOffset={24}
              >
                <Menu.Item>
                  <Button
                    type="link"
                    href="https://schoolbi-public-static.s3.us-east-1.amazonaws.com/data-connetor/SchoolBI.mez"
                    className="font-bold color-white p-0"
                  >
                    Download PowerBI
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    type="link"
                    href={`https://lookerstudio.google.com/datasources/create?connectorId=${
                      LookerStudioConnecterId?.[getEnv()]
                    }`}
                    className="font-bold color-white d-flex justify-content-start p-0"
                  >
                    Looker Studio
                  </Button>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
          {/* Version Container */}
          {/* <div className="version"> */}
          {/* <Button type="link" href="https://schoolbi-public-static.s3.us-east-1.amazonaws.com/data-connetor/SchoolBI.mez" >Download</Button> */}
          {/* Version Number */}
          {/* <h2 className="color-white text-center fs-18 ">
              {t(version)}{" "}
              {process.env.REACT_APP_VERSION
                ? process.env.REACT_APP_VERSION
                : "0.1.0"}
            </h2> */}
          {/* Copyright Text */}
          {/* <p className="color-white text-center fs-16">{t(CopyrightText)}</p> */}
          {/* </div> */}
        </div>
      </Sider>
      {/*  Sider Container Start  */}

      <LogoutModal
        logoutModalOpen={logoutModalOpen}
        setLogoutModalOpen={setLogoutModalOpen}
        {...props}
      />
    </>
  );
};

export default Sider;
