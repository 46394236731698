export const Pages = {
  Help: require("Pages/Help/Help").default,
  Home: require("./Home/Home").default,
  Admin: require("./Admin/index").default,
  Research: require("./Research/Research").default,
  Dashboard: require("./Dashboard/Dashboard").default,
  Login_Module: {
    Login: require("./Login_Module/Login/Index").default,
    CreatePassword: require("./Login_Module/Login/CreatePassword").default,
    ForgotPassword: require("./Login_Module/ForgotPassword/Index").default,
    CreateAccount: require("./Login_Module/CreateAccount").default,
    ConfirmSignupEmail:
      require("./Login_Module/CreateAccount/ConfirmSignupEmail").default,
    ConfirmPassword: require("./Login_Module/ForgotPassword/ConfirmPassword")
      .default,
  },
  Profile_Module: {
    CompanySettings: require("./Login_Module/Account/CompanySettings").default,
    ProfileSettings: require("./Login_Module/Profile/ProfileSettings").default,
  },
  Account_Setup_Module: {
    Selection: require("./Login_Module/Account/Selection").default,
  },
  Auth_Module: {
    SIGNIN: require("Pages/Auth/Signin").default,
    SIGNUP: require("Pages/Auth/Signup").default,
    FORGETPASSWORD: require("Pages/Auth/ForgotPassword").default,
    RESETPASSWORD: require("Pages/Auth/ResetPassword").default,
    CREATEPASSWORD: require("Pages/Auth/CreatePassword").default,
    CONFIRMEMAIL: require("Pages/Auth/ConfirmEmail").default,
  },
  Manage_Files_Module: require("./ManageFiles/ManageFiles").default,
  Manage_Datasets_Module: require("./ManageDatasets/ManageDatasets").default,
  Data_Management_Module: {
    Data_Provider: require("./Login_Module/Account/DataSource").default,
    Configure_Module: {
      Advanced_List: require("./ManageDatasets/AdvancedList").default,
      DataSet: require("./ManageDatasets/DataSource").default,
    },
  },
  Rule_Engine_Module: {
    RuleEngine: require("./RuleEngine/RuleEngine").default,
    AddNewRuleEngine: require("./RuleEngine/AddNewRule").default,
  },
  Data_Package_Module: {
    DataPackage: require("./DataPackage/DataPackage").default,
  },
  Manage_AdvancedList_Module: {
    ManageAdvancedList: require("./ManageAdvancedList/ManageAdvancedList")
      .default,
  },
  Manage_AllData_Module: {
    ManageAllDataModule: require("./ManageAllData/ManageAllData").default,
  },
  Manage_NewData_Module: {
    ManageNewDataModule: require("./ManageNewDataModule/ManageNewDataModule")
      .default,
  },
  Manage_Datasets_And_Rules_Engine_Module: {
    ManageDatasetsAndRulesEngine:
      require("./ManageDatasetsAndRulesEngine/ManageDatasetsAndRuleEngine")
        .default,
  },
  QuickSightDashboard_Module: {
    QuickSightDashboardModule: require("./QSDashboard/index.js").default,
    QuickSightDashboardViewModule: require("./QSDashboard/QSDashboardView")
      .default,
  },
};
